import { Filter } from "types";
import { useInsightQuery } from "./use-insight-query";


const MOST_IMPACTFUL_INSIGHTS_QUERY_NAME = 'insight_most_impactful_materials';

export const useMostImpactfulInsightsReport = (params?: Filter[])  => {
  

    const mostImpactfulInsightsResult = useInsightQuery<{
        beautified_name_with_characteristic : string;
        absolute_co2_emissions: number;
        absolute_water_depletion: number;
        absolute_land_use:number;
    }>(MOST_IMPACTFUL_INSIGHTS_QUERY_NAME);

   
    
    return {mostImpactfulInsightsResult, params }
};
