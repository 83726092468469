import { useTranslation } from 'react-i18next';
import { LookerDashboardTabs } from './looker-dashboard-tabs';

const dataQualityTabOptions = [
    { name: 'Category', dashboardId: 23 },
    { name: 'Product Weights', dashboardId: 21 },
    { name: 'Country of Production', dashboardId: 22 },
    { name: 'Dayrize Score', dashboardId: 24 }
];

export const DataQualityInsights = () => {
    const { t } = useTranslation();
    return (
        <LookerDashboardTabs
            title={t('dataQualityInsights')}
            description={t('dataQualityInsightsDescription')}
            tabOptions={dataQualityTabOptions}
        />
    );
};
