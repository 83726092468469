import { useTableFilters } from 'shared/use-table-filters';
import useSWR from 'swr';
import { QueryResult, User } from 'types';
import { useEffect } from 'react';

export const useOrganizationUsers = () => {
    const { query, onPageTokenMappingChange, order, onOrderChange, page, onPage, onLimitChange, limit, search, onSearchChange } =
        useTableFilters({
            searchKey: 'name'
        });
    const { data, isLoading, error, mutate } = useSWR<QueryResult<User>>(['/account_manager/users/query', query]);

    useEffect(() => {
        const shouldUpdate = data && !isLoading && data.next_page_token;
        if (shouldUpdate) {
            onPageTokenMappingChange((current) => ({
                ...current,
                [page + 1]: data.next_page_token
            }));
        }
    }, [data, isLoading, onPageTokenMappingChange, page]);

    return { data, error, mutate, isLoading, page, onPage, onLimitChange, onOrderChange, onSearchChange, search, limit, order };
};
