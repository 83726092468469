import { StatusCodes } from 'http-status-codes';
import { paths } from 'paths';
import { Organization } from 'types';
import { request } from 'lib/request-client';

export const getOrganizationRedirectPath = async ({ authDomain, namespaceId }: { authDomain?: string; namespaceId?: string | null }) => {
    try {
        const organization = (await request
            .url(`/account_manager/organization/${namespaceId ? 'namespace_id' : 'auth_domain'}/${namespaceId ?? authDomain}`)
            .get()) as Organization;
        return paths.home(organization.id);
    } catch (error: any) {
        if ([StatusCodes.FORBIDDEN, StatusCodes.UNAUTHORIZED].includes(error?.status)) return paths.requestAccess();
        if (StatusCodes.NOT_FOUND === error?.status) return paths.createMainOrganization();
        return paths.error();
    }
};
