import { NAMESPACE_STORAGE_KEY } from 'account-management/auth/use-auth';
import { paths } from 'paths';
import { useComponentToPdf } from 'products/use-component-to-pdf';
import { DownloadButton } from 'shared/download-button';
import { ErrorCard } from 'shared/error-card';
import { ImpactList } from 'shared/impact-list';
import { ImpactNumberInput } from 'shared/impact-number-input';
import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import { PreviewBadge } from 'shared/preview-badge';
import { ProductFilters } from 'shared/product-filters';
import { Section } from 'shared/section';
import { StaticTable } from 'shared/static-table';
import { TooManyResultsAlert } from 'shared/too-many-results-alert';
import { useFeatureEnabled } from 'shared/use-feature-enabled';
import { useOrganizationId } from 'shared/use-organization-id';
import { startTransition, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbAlertCircle, TbDownload } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { Alert, Anchor, Box, Button, Group, Stack, Text, Title } from '@mantine/core';
import { useSessionStorage } from '@mantine/hooks';
import { FlagName } from 'utils/get-flag-value';
import { DEFAULT_MAX_RESULTS } from 'utils/run-query';
import { showErrorMessage } from 'utils/show-error-message';
import { sumArr } from 'utils/sum-arr';
import { toFixedIfNeeded } from 'utils/to-fixed-if-needed';
import { useProductFilters } from '../shared/use-product-filters';
import { usePrism } from './use-prism';

const DEFAULT_QUANTITY = 1;

const formatValue = (value: number | null) => (Number.isNaN(value) || value === null ? '-' : toFixedIfNeeded(value, 2));

export const Prism = () => {
    const { isEnabled } = useFeatureEnabled();
    const containerRef = useRef<HTMLDivElement>(null);
    const onPdf = useComponentToPdf(containerRef, {
        filename: `range-impact-simulator.pdf`
    });

    const { t } = useTranslation();
    const organizationId = useOrganizationId();
    const { filters, ...rest } = useProductFilters();
    const { data, error, isLoading } = usePrism(filters);

    const [isPdfGenerating, setIsPdfGenerating] = useState(false);

    const [productToQuantityMap, setProductToQuantityMap] = useSessionStorage<{
        [productId: string]: {
            originalQuantity: number;
            modelledQuantityFocused?: boolean;
            modelledQuantityChanged?: boolean;
            modelledQuantity: number;
        };
    }>({
        key: 'prism',
        defaultValue: {}
    });

    const isImpersonating = !!window.sessionStorage.getItem(NAMESPACE_STORAGE_KEY);

    const getTotalValues = useCallback(
        ({
            carbonEmissions,
            waterDepletion,
            landUse,
            originalCarbonEmissions,
            originalWaterDepletion,
            originalLandUse
        }: {
            carbonEmissions?: number;
            waterDepletion?: number;
            landUse?: number;
            originalCarbonEmissions?: number;
            originalWaterDepletion?: number;
            originalLandUse?: number;
        } = {}) => [
            {
                title: t('carbonEmissions'),
                rawValue: carbonEmissions ?? 0,
                value: carbonEmissions ? formatValue(carbonEmissions) : null,
                unit: 'kg CO2e',
                original: originalCarbonEmissions
            },
            {
                title: t('waterDepletion'),
                rawValue: waterDepletion ?? 0,
                value: waterDepletion ? formatValue(waterDepletion) : null,
                unit: 'liters',
                original: originalWaterDepletion
            },
            {
                title: t('landUse'),
                rawValue: landUse ?? 0,
                value: landUse ? formatValue(landUse) : null,
                unit: '㎡',
                original: originalLandUse
            }
        ],
        [t]
    );

    const { original, modelled } = useMemo(() => {
        if (!data?.results) return { original: getTotalValues(), modelled: getTotalValues() };

        const formatted = data.results.map((current) => {
            const carbonEmissions =
                current.packaging_gwp_absolute +
                current.manufacturing_gwp_absolute +
                current.product_gwp_absolute +
                current.transport_gwp_absolute;
            const waterDepletion = current.absolute_water_depletion_packaging + current.absolute_water_depletion_product;
            const landUse = current.absolute_alop_packaging + current.absolute_alop_product;
            const productQuantity = productToQuantityMap[current.id];
            return {
                originalCarbonEmissions: carbonEmissions * (productQuantity?.originalQuantity ?? DEFAULT_QUANTITY),
                modelledCarbonEmissions: carbonEmissions * (productQuantity?.modelledQuantity ?? DEFAULT_QUANTITY),
                originalWaterDepletion: waterDepletion * (productQuantity?.originalQuantity ?? DEFAULT_QUANTITY),
                modelledWaterDepletion: waterDepletion * (productQuantity?.modelledQuantity ?? DEFAULT_QUANTITY),
                originalLandUse: landUse * (productQuantity?.originalQuantity ?? DEFAULT_QUANTITY),
                modelledLandUse: landUse * (productQuantity?.modelledQuantity ?? DEFAULT_QUANTITY)
            };
        });

        const originalCarbonEmissions = sumArr(formatted, 'originalCarbonEmissions');
        const modelledCarbonEmissions = sumArr(formatted, 'modelledCarbonEmissions');
        const originalWaterDepletion = sumArr(formatted, 'originalWaterDepletion');
        const modelledWaterDepletion = sumArr(formatted, 'modelledWaterDepletion');
        const originalLandUse = sumArr(formatted, 'originalLandUse');
        const modelledLandUse = sumArr(formatted, 'modelledLandUse');

        return {
            original: getTotalValues({
                carbonEmissions: originalCarbonEmissions,
                waterDepletion: originalWaterDepletion,
                landUse: originalLandUse
            }),
            modelled: getTotalValues({
                carbonEmissions: modelledCarbonEmissions,
                originalCarbonEmissions,
                waterDepletion: modelledWaterDepletion,
                originalWaterDepletion,
                landUse: modelledLandUse,
                originalLandUse
            })
        };
    }, [data?.results, productToQuantityMap, getTotalValues]);

    if (error) {
        return <ErrorCard error={error} />;
    }

    const handleDownloadPdf = async () => {
        try {
            setIsPdfGenerating(true);
            await onPdf();
        } catch (error) {
            showErrorMessage(error);
        } finally {
            setIsPdfGenerating(false);
        }
    };

    const hasFilters = filters.length > 0;

    return (
        <>
            <Group align="center" justify="space-between" mb="md">
                <Group gap="xs">
                    <Title size="h3">{t('prism')}</Title>
                    <MoreInfoHoverCard>
                        <Text size="sm" c="dimmed">
                            {t('productAndRangeImpactSimulationModelDescription')}
                        </Text>
                    </MoreInfoHoverCard>
                    {isEnabled(FlagName.Prism, 'preview') && <PreviewBadge />}
                </Group>
                <ProductFilters {...rest}>
                    <Button
                        disabled={!data?.results}
                        loading={isPdfGenerating}
                        onClick={handleDownloadPdf}
                        variant="outline"
                        leftSection={<TbDownload size={16} />}
                    >
                        {t('downloadAsPdf')}
                    </Button>
                    <DownloadButton
                        disabled={!data?.results}
                        exports={[
                            {
                                name: 'range-impact-simulator',
                                data: data?.results
                            }
                        ]}
                    />
                </ProductFilters>
            </Group>
            <Section ref={containerRef}>
                {!hasFilters ? (
                    <Alert icon={<TbAlertCircle size={20} />} title={t('selectFilterRangeTitle')} color="blue">
                        {t('selectFilterRangeDescription')}
                    </Alert>
                ) : (
                    <>
                        {data?.num_results === DEFAULT_MAX_RESULTS && <TooManyResultsAlert maxResults={DEFAULT_MAX_RESULTS} />}
                        <Group align="center" justify="space-between" gap="md" mb="md">
                            <Stack align="center" flex={1} gap="md">
                                <Text ta="center" fw={600} size="lg">
                                    {t('original')}
                                </Text>

                                <ImpactList data={original} />
                            </Stack>
                            <Stack align="center" flex={1} gap="md">
                                <Text ta="center" fw={600} size="lg">
                                    {t('modelled')}
                                </Text>

                                <ImpactList data={modelled} />
                            </Stack>
                        </Group>
                        <StaticTable
                            rowKey={(row) => `${row.id}_${row.version}`}
                            searchable={false}
                            isLoading={isLoading}
                            data={data?.results ?? []}
                            columns={[
                                {
                                    key: 'name',
                                    name: t('name'),
                                    render: (value, row) => (
                                        <Group align="center" mih={50} miw={240} maw={240}>
                                            {isImpersonating ? (
                                                <Anchor component={Link} to={paths.impactSimulator(organizationId, row.id)}>
                                                    {value}
                                                </Anchor>
                                            ) : (
                                                <Anchor target="_blank" href={paths.impactSimulator(organizationId, row.id)}>
                                                    {value}
                                                </Anchor>
                                            )}
                                        </Group>
                                    )
                                },
                                {
                                    key: 'current-quantity',
                                    name: t('currentQuantity'),
                                    render: (_, row) => {
                                        const value = productToQuantityMap[row.id]?.originalQuantity ?? DEFAULT_QUANTITY;
                                        return (
                                            <ImpactNumberInput
                                                variant="range"
                                                maw={120}
                                                min={DEFAULT_QUANTITY}
                                                allowNegative={false}
                                                allowDecimal={false}
                                                value={value}
                                                onChange={(value) => {
                                                    startTransition(() => {
                                                        setProductToQuantityMap((prev) => {
                                                            const modelledQuantityChanged = prev[row.id]?.modelledQuantityChanged ?? false;
                                                            const modelledQuantityFocused = prev[row.id]?.modelledQuantityFocused ?? false;

                                                            return {
                                                                ...prev,
                                                                [row.id]: {
                                                                    modelledQuantityChanged,
                                                                    modelledQuantityFocused,
                                                                    originalQuantity: value,
                                                                    modelledQuantity:
                                                                        modelledQuantityChanged && modelledQuantityFocused
                                                                            ? prev[row.id]?.modelledQuantity
                                                                            : value
                                                                }
                                                            };
                                                        });
                                                    });
                                                }}
                                            />
                                        );
                                    }
                                },
                                {
                                    key: 'modelled-quantity',
                                    name: t('modelledQuantity'),
                                    render: (_, row) => {
                                        const value = productToQuantityMap[row.id]?.modelledQuantity ?? DEFAULT_QUANTITY;

                                        return (
                                            <ImpactNumberInput
                                                variant="range"
                                                maw={120}
                                                min={DEFAULT_QUANTITY}
                                                allowNegative={false}
                                                allowDecimal={false}
                                                value={value}
                                                onFocus={() => {
                                                    startTransition(() => {
                                                        setProductToQuantityMap((prev) => ({
                                                            ...prev,
                                                            [row.id]: {
                                                                ...prev[row.id],
                                                                modelledQuantityFocused: true
                                                            }
                                                        }));
                                                    });
                                                }}
                                                onChange={(value) => {
                                                    startTransition(() => {
                                                        setProductToQuantityMap((prev) => ({
                                                            ...prev,
                                                            [row.id]: {
                                                                ...prev[row.id],
                                                                modelledQuantity: value,
                                                                modelledQuantityChanged: true
                                                            }
                                                        }));
                                                    });
                                                }}
                                            />
                                        );
                                    }
                                },
                                {
                                    key: 'carbon',
                                    align: 'center',
                                    name: `${t('carbonEmissions')} (kg CO2e)`,
                                    render: (_, row) => {
                                        const total =
                                            row.packaging_gwp_absolute +
                                            row.manufacturing_gwp_absolute +
                                            row.product_gwp_absolute +
                                            row.transport_gwp_absolute;
                                        return (
                                            <Box ta="center" w="100%">
                                                {formatValue(total)}
                                            </Box>
                                        );
                                    }
                                },
                                {
                                    key: 'water',
                                    align: 'center',
                                    name: `${t('waterDepletion')} (liters)`,
                                    render: (_, row) => {
                                        const total = row.absolute_water_depletion_packaging + row.absolute_water_depletion_product;
                                        return (
                                            <Box ta="center" w="100%">
                                                {formatValue(total)}
                                            </Box>
                                        );
                                    }
                                },
                                {
                                    key: 'land',
                                    name: `${t('landUse')} (㎡)`,
                                    align: 'center',
                                    render: (_, row) => {
                                        const total = row.absolute_alop_packaging + row.absolute_alop_product;
                                        return (
                                            <Box ta="center" w="100%">
                                                {formatValue(total)}
                                            </Box>
                                        );
                                    }
                                }
                            ]}
                        />
                    </>
                )}
            </Section>
        </>
    );
};
