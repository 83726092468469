import { useCircularityOfMaterials } from 'insights-explorer/use-circularity-of-materials';
import { DownloadButton } from 'shared/download-button';
import { formatEol } from 'shared/format-eol';
import { GraphLegend } from 'shared/graph-legend';
import { ProductFilters } from 'shared/product-filters';
import { Section } from 'shared/section';
import { useBiobasedMaterialsMapping } from 'shared/use-biobased-materials-mapping';
import { useProductFilters } from 'shared/use-product-filters';
import { useRecycledOrReusedMaterialsMapping } from 'shared/use-recycled-or-reused-materials-mapping';
import { WaffleGraph } from 'shared/waffle-graph';
import { ValueWidget as ValueWidgetType } from 'types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Group, Skeleton, Stack, Text, Title, rem } from '@mantine/core';
import { toPercentage } from 'utils/to-percentage';
import { useEolMapping } from '../shared/use-eol-mapping';
import { NoDataAlert } from './no-data-alert';
import { useMediaQuery } from '@mantine/hooks';

type ReusedMaterialsMappingKey = keyof ReturnType<typeof useRecycledOrReusedMaterialsMapping>;
type BiobasedMaterialsMappingKey = keyof ReturnType<typeof useBiobasedMaterialsMapping>;
type EolMappingKey = keyof ReturnType<typeof useEolMapping>;

export const CircularityOfMaterials = ({ config }: { config: ValueWidgetType }) => {
    const { filters, ...rest } = useProductFilters() || {};
    const recycledOrReusedMaterialsMapping = useRecycledOrReusedMaterialsMapping();
    const biobasedMaterialsMapping = useBiobasedMaterialsMapping();
    const eolMapping = useEolMapping();
    const { t } = useTranslation();
    const { biobasedResults, circularResults, eolResults } = useCircularityOfMaterials(filters);
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');

    const recycledOrReusedMaterials = useMemo(() => {
        const validResults =
            circularResults.data?.results
                .filter(
                    (current) =>
                        current.characteristic &&
                        recycledOrReusedMaterialsMapping[current.characteristic as ReusedMaterialsMappingKey] &&
                        current.percentage !== null
                )
                .map((current) => ({
                    material_category: current.material_category,
                    value: current.percentage!,
                    ...recycledOrReusedMaterialsMapping[current.characteristic as ReusedMaterialsMappingKey]
                })) ?? [];
        return {
            product: validResults.filter((current) => current.material_category === 'product'),
            packaging: validResults.filter((current) => current.material_category === 'packaging')
        };
    }, [circularResults.data?.results, recycledOrReusedMaterialsMapping]);

    const navigate = useNavigate();
    const handleClick = () => {
        if (config?.link) {
            navigate(config.link);
        }
    };

    const bioBasedMaterials = useMemo(() => {
        const validResults =
            biobasedResults.data?.results
                .filter(
                    (current) =>
                        current.characteristic &&
                        biobasedMaterialsMapping[current.characteristic as BiobasedMaterialsMappingKey] &&
                        current.percentage !== null
                )
                .map((current) => ({
                    material_category: current.material_category,
                    value: current.percentage!,
                    ...biobasedMaterialsMapping[current.characteristic as BiobasedMaterialsMappingKey]
                })) ?? [];
        return {
            product: validResults.filter((current) => current.material_category === 'product'),
            packaging: validResults.filter((current) => current.material_category === 'packaging')
        };
    }, [biobasedResults.data?.results, biobasedMaterialsMapping]);

    const eolMaterials = useMemo(() => {
        const validResults =
            eolResults.data?.results
                .map(formatEol)
                .filter(
                    (current) =>
                        current.characteristic &&
                        eolMapping[current.characteristic as EolMappingKey] &&
                        current.percentage !== null &&
                        current.percentage > 0
                )
                .map((current) => ({
                    material_category: current.material_category,
                    value: current.percentage!,
                    ...eolMapping[current.characteristic as EolMappingKey]
                })) ?? [];
        return {
            product: validResults.filter((current) => current.material_category === 'product'),
            packaging: validResults.filter((current) => current.material_category === 'packaging')
        };
    }, [eolResults.data?.results, eolMapping]);

    const sections = useMemo(() => {
        return [
            {
                key: 'circular',
                title: t('percentageOfRecycledOrReusedMaterials'),
                description: t('percentageOfRecycledOrReusedMaterialsDescription'),
                isEmpty: !circularResults.isLoading && circularResults.data?.results.length === 0,
                isLoading: circularResults.isLoading,
                data: recycledOrReusedMaterials
            },
            {
                key: 'biobased',
                title: t('percentageOfBiobasedMaterials'),
                description: t('percentageOfBiobasedMaterialsDesciption'),
                isEmpty: !biobasedResults.isLoading && biobasedResults.data?.results.length === 0,
                isLoading: biobasedResults.isLoading,
                data: bioBasedMaterials
            },
            {
                key: 'eol',
                title: t('proportionOfMaterialsPerRecyclabilityCategory'),
                description: t('proportionOfMaterialsPerRecyclabilityCategoryDescription'),
                isEmpty: !eolResults.isLoading && eolResults.data?.results.length === 0,
                isLoading: eolResults.isLoading,
                data: eolMaterials
            }
        ];
    }, [recycledOrReusedMaterials, bioBasedMaterials, eolMaterials, circularResults, biobasedResults, eolResults, t]);

    const shouldShowExport = biobasedResults?.data?.results && circularResults.data?.results && eolResults.data?.results;

    return (
        <button
            onClick={handleClick}
            style={{
                background: 'none',
                border: 'none',
                padding: 0,
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}
        >
            {' '}
            {!config && (
                <Group justify="space-between" mb="md">
                    <Box>
                        <Title style={{textAlign:  isSmallDevice  ? "center" : "left"}} size="h3">{t('circularityOfMaterials')}</Title>
                        <Text size="sm" c="dimmed">
                            {t('circularityOfMaterialsDescription')}
                        </Text>
                    </Box>

                    <ProductFilters enabledFilters={['brand', 'category']} {...rest}>
                        {shouldShowExport && (
                            <DownloadButton 
                                exports={[
                                    {
                                        name: 'insight_circularity_of_materials_biobased',
                                        data: biobasedResults.data!.results
                                    },
                                    {
                                        name: 'insight_circularity_of_materials_circular',
                                        data: circularResults.data!.results
                                    },
                                    {
                                        name: 'insight_circularity_of_materials_eol',
                                        data: eolResults.data!.results
                                    }
                                ]}
                            />
                        )}
                    </ProductFilters>
                </Group>
            )}
            {/* Render only the first section if config is falsy */}
            {sections.slice(0, config ? 1 : sections.length).map((section) => (
                <Section key={section.key} mb="md">
                    <Title size="h4">{section.title}</Title>
                    {section.isEmpty ? (
                        <NoDataAlert />
                    ) : section.isLoading ? (
                        <Skeleton height={200} data-testid={`loading-${section.key}`} my="lg" />
                    ) : (
                        <Group my={rem(40)} justify="center" gap={rem(48)} align="flex-start">
                            {section.data.product.length > 0 && (
                                <Stack align="center" gap="lg">
                                    <WaffleGraph data={section.data.product} title={t('productMaterials')} />
                                    <GraphLegend
                                        items={section.data.product.map((row) => ({
                                            ...row,
                                            subtitle: '',
                                            value: toPercentage(row.value)
                                        }))}
                                    />
                                </Stack>
                            )}

                            {section.data.packaging.length > 0 && (
                                <Stack align="center" gap="lg">
                                    <WaffleGraph data={section.data.packaging} title={t('packagingMaterials')} />
                                    <GraphLegend
                                        items={section.data.packaging.map((row) => ({
                                            ...row,
                                            subtitle: '',
                                            value: toPercentage(row.value)
                                        }))}
                                    />
                                </Stack>
                            )}
                        </Group>
                    )}

                    <Text c="dimmed" size="sm">
                        {section.description}
                    </Text>
                </Section>
            ))}
        </button>
    );
};
