export const getLargestRemainder = (values: { value: number }[], desiredSum: number) => {
    let sum = 0;
    let valueParts = values.map((item, index: number) => {
        let integerValue = item.value | 0;
        sum += integerValue;
        return {
            item,
            integer: integerValue,
            decimal: item.value % 1,
            originalIndex: index
        };
    });

    if (sum !== desiredSum && sum !== 0) {
        valueParts = valueParts.sort((a, b) => b.decimal - a.decimal);

        const diff = desiredSum - sum;
        let i = 0;

        while (i < diff) {
            valueParts[i].integer++;
            i++;
        }
    }

    return valueParts
        .sort((a, b) => a.originalIndex - b.originalIndex)
        .map((p) => ({
            ...p.item,
            value: p.integer
        }));
};
