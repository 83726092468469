import { ErrorAlert } from 'shared/error-alert';
import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import { Section } from 'shared/section';
import { ReactNode } from 'react';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';
import { Group, Skeleton, Text, rem } from '@mantine/core';

export const ValueCard = ({
    title,
    value,
    description,
    to,
    error,
    unit,
    filters
}: {
    unit?: string;
    error?: Error;
    to?: string;
    title: string;
    description?: string;
    value?: number;
    filters?: ReactNode;
}) => {
    const navigate = useNavigate();
    const isLoading = typeof value !== 'number';

    const handleClick = () => {
        if (to) {
            navigate(to);
        }
    };

    return (
        <button
            onClick={handleClick}
            style={{
                background: 'none',
                border: 'none',
                padding: 0,
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}
            aria-label={title} 
        >
            {' '}
            <Section
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    cursor: 'pointer'
                }}
            >
                <Group justify="space-between" align="center" mb="md" gap="xs">
                    <Group gap={rem(2)}>
                        <Text fw={700} size="md">
                            {title}
                        </Text>

                        {description && (
                            <MoreInfoHoverCard>
                                <Text size="sm" c="dimmed">
                                    {description}
                                </Text>
                            </MoreInfoHoverCard>
                        )}
                    </Group>
                    {filters}
                </Group>

                {error ? (
                    <ErrorAlert error={error} />
                ) : isLoading ? (
                    <Skeleton height={58} width={60} mt="xs" data-testid="loading" />
                ) : (
                    <Group justify="space-between" align="end">
                        <Text fw={700} size="display" mt="xs" lh={1}>
                            <CountUp end={value} suffix={unit} duration={1} separator=" " decimal="," />
                        </Text>
                    </Group>
                )}
            </Section>
        </button>
    );
};
