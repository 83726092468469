import { Bar, BarChart, CartesianGrid, Cell, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import ScoreColorLegend from 'shared/score-color-legend';
import { Skeleton, useMantineColorScheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useScoreColor } from 'utils/use-score-color';
import { NoDataAlert } from './no-data-alert';

type Item = { id: string; name: string; value: number; metric: string };

export const ComparisonChart = ({
    data,
    isLoading = false,
    dimension,
    onClick
}: {
    data: Array<Item>;
    isLoading: boolean;
    dimension: string;
    onClick?: (data: Item) => void;
}) => {
    const getScoreColor = useScoreColor();
    const { colorScheme } = useMantineColorScheme();
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');

    const hasNoData = data.length === 0;

    const labelColor = colorScheme === 'dark' ? '#fff' : '#656565';

    if (isLoading) return <Skeleton height={300} data-testid="score-comparison-loading" />;

    if (hasNoData) return <NoDataAlert />;

    return (
        <>
            <ResponsiveContainer height={data.length * 40 + 40} width="100%">
                <BarChart
                    data={data}
                    height={800}
                    layout="vertical"
                    maxBarSize={24}
                    width={1120}
                    margin={{
                        right: 80,
                        bottom: 0
                    }}
                >
                    <CartesianGrid horizontal={false} stroke={colorScheme === 'dark' ? '#5d5e61' : '#e7e7e7'} />
                    <YAxis
                        axisLine={false}
                        dataKey="name"
                        stroke={labelColor}
                        fontSize={12}
                        tickLine={false}
                        type="category"
                        width={isSmallDevice ? 120 : 208}
                    />
                    <XAxis
                        axisLine={false}
                        dataKey="value"
                        domain={[0, 100]}
                        fontSize={12}
                        stroke={labelColor}
                        tickLine={false}
                        type="number"
                    />
                    <Bar animationBegin={200} dataKey="value" fill="#8884d8" radius={[4, 4, 4, 4]}>
                        {data.map((entry) => (
                            <Cell
                                key={`${entry.id}-${entry.value}`}
                                style={{ cursor: 'pointer' }}
                                fill={getScoreColor(entry.value, entry.metric)}
                                onClick={() => onClick?.(entry)}
                            />
                        ))}
                        <LabelList
                            dataKey="value"
                            fill={labelColor}
                            fontSize={12}
                            formatter={(value: number) => value.toLocaleString()}
                            position="right"
                        />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            {!['carbon_emissions', 'water_depletion', 'land_use'].includes(dimension) && <ScoreColorLegend />}
        </>
    );
};
