import { OrderDirection } from 'types/shared';
import { ReactNode } from 'react';
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb';
import { ActionIcon, Box, Checkbox, Group, Table as MantineTable, Select, Skeleton, rem, useMantineColorScheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Search } from './search';
import { Column, TableHeader } from './table-header';
import { TableRows } from './table-rows';

const NUMBER_OF_PLACEHOLDER_ROWS = 5;

export const Table = ({
    search,
    onSearchChange,
    limit,
    onLimitChange,
    page,
    onPage,
    order,
    onOrderChange,
    orderDirection = OrderDirection.Descending,
    columns,
    data = [],
    isLoading = false,
    rowKey = 'key',
    actions,
    maxHeight,
    selected = [],
    onSelect,
    expandPageSizeOptions = false
}: {
    selected?: Array<string>;
    onSelect?: (id: Array<string>, rows: any[]) => void;
    search?: string;
    onSearchChange?: (search: string) => void;
    limit?: string;
    onLimitChange?: (newLimit: string) => void;
    page?: number;
    onPage?: (newPage: number) => void;
    order?: string;
    orderDirection?: OrderDirection;
    onOrderChange?: (newOrder: string) => void;
    columns: Column[];
    rowKey?: ((row: any) => string) | string;
    data?: any[];
    isLoading?: boolean;
    actions?: ReactNode;
    maxHeight?: number | string;
    expandPageSizeOptions?: boolean;
}) => {
    const { colorScheme } = useMantineColorScheme();
    const isSearchSupported = onSearchChange && typeof search === 'string';
    const isLimitSupported = onLimitChange && typeof limit === 'string';
    const isPageSupported = onPage && typeof page === 'number';
    const isOrderSupported = onOrderChange && typeof order === 'string';
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');

    const isSelectable = !!onSelect;

    const isSelected = (row: any) => {
        const id = typeof rowKey === 'string' ? row[rowKey] : rowKey(row);
        return selected.includes(id);
    };

    const isAllSelected = isSelectable && data.every(isSelected);
    const isSomeSelected = !isAllSelected && data.some(isSelected);
    const defaultMaxHeight = isSmallDevice ? '58vh' : '63vh';
    const pageSizeOptions = ['10', '20', '50', '100'];
    if (expandPageSizeOptions) {
        pageSizeOptions.push('200', '500', '1000');
    }

    return (
        <>
            {(isSearchSupported || actions) && (
                <Group
                    gap="sm"
                    mb="xs"
                    style={{
                        justifyContent: isSmallDevice ? 'flex-end' : isSearchSupported ? 'space-between' : 'flex-end'
                    }}
                >
                    {isSearchSupported && <Search value={search} onChange={(value) => onSearchChange(value)} />}
                    {actions}
                </Group>
            )}

            <Box
                data-mantine-color-scheme={colorScheme}
                style={{ maxWidth: '100%', overflowX: 'auto', maxHeight: maxHeight ?? defaultMaxHeight, overflow: 'auto' }}
            >
                <MantineTable stickyHeader stickyHeaderOffset={-12}>
                    <Box component={MantineTable.Thead}>
                        <MantineTable.Tr>
                            {isSelectable && (
                                <Box component={MantineTable.Th} w={rem(36)}>
                                    <Checkbox
                                        disabled={isLoading}
                                        data-testid="select-all"
                                        size="xs"
                                        radius="xs"
                                        indeterminate={isSomeSelected}
                                        checked={isAllSelected}
                                        onChange={(event) => {
                                            const allIds = data.map((row) => {
                                                return typeof rowKey === 'string' ? row[rowKey] : rowKey(row);
                                            });
                                            const selectedExcludingCurrentData = selected.filter((current) => !allIds.includes(current));
                                            if (event.currentTarget.checked) {
                                                onSelect([...selectedExcludingCurrentData, ...allIds], data);
                                            } else {
                                                onSelect([], []);
                                            }
                                        }}
                                    />
                                </Box>
                            )}
                            {columns.map((column) => (
                                <TableHeader
                                    key={column.key}
                                    sortDirection={orderDirection}
                                    isOrderSupported={isOrderSupported}
                                    column={column}
                                    sortColumn={order}
                                    onSort={() => {
                                        if (onOrderChange) onOrderChange(column.key);
                                    }}
                                />
                            ))}
                        </MantineTable.Tr>
                    </Box>

                    <tbody>
                        {isLoading ? (
                            Array.from({ length: isLimitSupported && limit ? Number.parseInt(limit) : NUMBER_OF_PLACEHOLDER_ROWS }).map(
                                (_, index) => {
                                    return (
                                        <MantineTable.Tr data-testid={`${index}-loading`} key={index}>
                                            {isSelectable && <td />}
                                            {columns.map((column, innerIndex) => {
                                                return (
                                                    <MantineTable.Td key={column.key ?? innerIndex}>
                                                        <Skeleton width={72} height={18} />
                                                    </MantineTable.Td>
                                                );
                                            })}
                                        </MantineTable.Tr>
                                    );
                                }
                            )
                        ) : (
                            <TableRows
                                rowKey={rowKey}
                                data={data}
                                columns={columns}
                                search={search}
                                isSearchSupported={isSearchSupported}
                                selected={selected}
                                onSelect={onSelect}
                            />
                        )}
                    </tbody>
                </MantineTable>
            </Box>

            {(isLimitSupported || isPageSupported) && (
                <Group mt={'sm'} justify="flex-end" gap="sm">
                    {isLimitSupported && (
                        <Select
                            disabled={isLoading}
                            data={pageSizeOptions}
                            size="sm"
                            data-testid="paging"
                            value={limit}
                            w={rem(84)}
                            onChange={(value) => {
                                if (value) onLimitChange(value);
                            }}
                        />
                    )}
                    {isPageSupported && (
                        <Group gap="xs">
                            <ActionIcon size="lg" onClick={() => onPage(page - 1)} disabled={page === 1 || isLoading}>
                                <TbChevronLeft size={16} />
                            </ActionIcon>
                            <ActionIcon
                                size="lg"
                                onClick={() => onPage(page + 1)}
                                disabled={data.length < Number.parseInt(limit!, 10) || isLoading}
                            >
                                <TbChevronRight size={16} />
                            </ActionIcon>
                        </Group>
                    )}
                </Group>
            )}
        </>
    );
};
