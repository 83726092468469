import { LookerEmbedSDK } from '@looker/embed-sdk';
import { useSignedUrl } from 'insights-explorer/use-signed-url';
import styled from 'styled-components';
import React, { useEffect, useRef } from 'react';
import { TbAlertCircle } from 'react-icons/tb';
import { Alert, useMantineColorScheme } from '@mantine/core';

interface LookerDashboardProps {
    dashboardId: number;
    style?: React.CSSProperties;
}

const LIGHT_THEME = 'Dayrize';
const DARK_THEME = 'Dayrize_Dark';
const lookerHost = import.meta.env.VITE_LOOKER_HOST;

LookerEmbedSDK.init(lookerHost);

const LookerDashboard: React.FC<LookerDashboardProps> = ({ dashboardId, style }) => {
    const embedContainer = useRef<HTMLDivElement>(null);
    const dashboardInstanceRef = useRef<any>(null);
    const previousDashboardId = useRef<number | null>(null);

    const { colorScheme } = useMantineColorScheme();
    const lookerTheme = colorScheme === 'dark' ? DARK_THEME : LIGHT_THEME;
    const embedDomain = `${window.location.protocol}//${window.location.hostname}`;

    const { data, error, isLoading } = useSignedUrl(dashboardId, lookerTheme, embedDomain);

    useEffect(() => {
        const setupDashboard = async () => {
            if (embedContainer.current && data && !error && !isLoading) {
                if (!dashboardInstanceRef.current) {
                    embedContainer.current.innerHTML = '';

                    if (previousDashboardId.current !== dashboardId) {
                        if (dashboardInstanceRef.current) {
                            dashboardInstanceRef.current.destroy();
                            dashboardInstanceRef.current = null;
                        }

                        LookerEmbedSDK.createDashboardWithId(dashboardId)
                            .appendTo(embedContainer.current)
                            .withParams({
                                embed_domain: window.location.origin
                            })
                            .withClassName('looker-dashboard')
                            .withFrameBorder('0')
                            .withUrl(data.signedUrl)
                            .withApiHost(lookerHost)
                            .build()
                            .connect()
                            .then((dashboard: any) => {
                                dashboardInstanceRef.current = dashboard;
                                previousDashboardId.current = dashboardId;
                            });
                    }
                }
            }
        };

        setupDashboard();

        return () => {
            if (dashboardInstanceRef.current) {
                dashboardInstanceRef.current.destroy();
                dashboardInstanceRef.current = null;
                previousDashboardId.current = null;
            }
        };
    }, [dashboardId, data, error, isLoading, lookerTheme]);

    if (error) return <Alert icon={<TbAlertCircle size={20} />} color="orange" variant="light" mb="md" title="Could not load dashboard" />;
    if (isLoading) return <div>Loading...</div>;

    return <Dashboard ref={embedContainer} data-testid="dashboard-container" style={style} />;
};

const Dashboard = styled.div`
    width: 100%;
    height: calc(100% - 10px);

    & > iframe {
        width: 100%;
        height: 100%;
        background: transparent;
    }
`;

export default LookerDashboard;
