import { useTranslation } from 'react-i18next';
import { LookerDashboardTabs } from './looker-dashboard-tabs';

const scorecardTabOptions = [
    { name: 'Category', dashboardId: 19 },
    { name: 'Brand', dashboardId: 18 }
];

export const Scorecards = () => {
    const { t } = useTranslation();
    return (
        <LookerDashboardTabs
            title={t('scorecardDashboard')}
            description={t('scorecardDashboardDescription')}
            tabOptions={scorecardTabOptions}
        />
    );
};
