import useSWR from 'swr';
import { Filter, Nullable, QueryReturnResult } from 'types';
import { ArrayParam, useQueryParam, withDefault } from 'use-query-params';
import { useMemo, useState } from 'react';
import { formatHeader } from 'utils/format-header';
import { runQuery } from 'utils/run-query';

const TAGS_QUERY_NAME = 'tags';
const SUPPLIERS_QUERY_NAME = 'suppliers';
const BRANDS_QUERY_NAME = 'product_brands';
const CATEGORIES_QUERY_NAME = 'product_categories';
const PARENT_CATEGORIES_QUERY_NAME = 'product_parent_categories';

const ALLOWED_REPEATED_FIELDS = ['tags', 'images', 'supplier'];
const SEARCH_DEFAULT_PAGE_SIZE = 200;

export const isValidArrFilter = (value: any) => Array.isArray(value) && value.length > 0 && value.every((current) => !!current);

export const addFilter = (filters: Filter[], key: string, value: any) => {
    const isValidFilter = isValidArrFilter(value);
    if (!isValidFilter) return;

    if (ALLOWED_REPEATED_FIELDS.includes(key)) {
        filters.push({
            name: key,
            operation: '&',
            value
        });
        return;
    }

    const isSingleFilter = value.length === 1 && !!value[0];
    filters.push({
        name: key,
        operation: isSingleFilter ? '=' : '|',
        value: isSingleFilter ? (value[0] as string) : value
    });
};

const filterNull = (items: Array<Nullable<string>>) => items.filter((current) => current !== null) as Array<string>;

const searchableQuery = (query: any) => {
    const [name, params] = query;
    return runQuery({
        name,
        pageSize: SEARCH_DEFAULT_PAGE_SIZE,
        params: params as Array<Filter>
    });
};

type ProductFilterResponse = {
    tag?: string | number;
    supplier?: string | number;
    brand?: string | number;
    name?: string;
    category?: string;
    category_id?: string | number;
    parent_category?: string;
};

const getOptions = (
    data: Array<ProductFilterResponse> | undefined,
    key: keyof ProductFilterResponse
): Array<{ label: string; value: string }> => {
    if (!data) return [];
    return data
        .map((current) => {
            const value = current[key];
            if (value == null) return;
            const label = ['category', 'name'].includes(key) ? formatHeader(value.toString()) : value.toString();
            let optionValue: string;

            if (key === 'category') {
                if (current.category_id != null) {
                    optionValue = current.category_id.toString();
                } else {
                    optionValue = value.toString();
                }
            } else {
                optionValue = value.toString();
            }

            return {
                label,
                value: optionValue
            };
        })
        .filter((option) => option?.value) as Array<{ label: string; value: string }>;
};

export const useProductFilters = () => {
    const [brandSearchText, setBrandSearchText] = useState('');
    const [supplierSearchText, setSupplierSearchText] = useState('');
    const [tagSearchText, setTagSearchText] = useState('');
    const [categorySearchText, setCategorySearchText] = useState('');

    const [suppliers, setSuppliers] = useQueryParam('supplier', withDefault(ArrayParam, []));
    const [tags, setTags] = useQueryParam('tags', withDefault(ArrayParam, []));

    // Sanitize tags: Remove apostrophes and filter out undefined values
    const sanitizedTags = (tags || []).map((tag) => (tag ?? '').replace(/'/g, '')).filter((tag) => tag !== '');
    setTags(sanitizedTags as (string | null)[]);

    const [brand, setBrand] = useQueryParam('brands', withDefault(ArrayParam, []));
    const [category, setCategoryQueryParam] = useQueryParam('categories', withDefault(ArrayParam, []));
    const [parentCategory, setParentCategory] = useQueryParam('parent_categories', withDefault(ArrayParam, []));

    const { data: tagsData, isLoading: isTagsLoading } = useSWR<QueryReturnResult<{ tag: string | number }>>(
        [
            TAGS_QUERY_NAME,
            tagSearchText
                ? [
                      {
                          name: 'tag',
                          operation: '~',
                          value: tagSearchText
                      }
                  ]
                : undefined
        ],
        searchableQuery
    );

    const { data: suppliersData, isLoading: isSuppliersLoading } = useSWR<QueryReturnResult<{ supplier: string | number }>>(
        [
            SUPPLIERS_QUERY_NAME,
            supplierSearchText
                ? [
                      {
                          name: 'supplier',
                          operation: '~',
                          value: supplierSearchText
                      }
                  ]
                : undefined
        ],
        searchableQuery
    );

    const { data: brands, isLoading: isBrandsLoading } = useSWR<QueryReturnResult<{ brand: string | number }>>(
        [
            BRANDS_QUERY_NAME,
            brandSearchText
                ? [
                      {
                          name: 'brand',
                          operation: '~',
                          value: brandSearchText
                      }
                  ]
                : undefined
        ],
        searchableQuery
    );

    const { data: parentCategories, isLoading: isParentCategoriesLoading } = useSWR<QueryReturnResult<{ parent_category: string }>>(
        PARENT_CATEGORIES_QUERY_NAME,
        () =>
            runQuery({
                name: PARENT_CATEGORIES_QUERY_NAME
            })
    );

    const categoryQuery: Array<Filter> = [];
    if (categorySearchText) {
        categoryQuery.push({
            name: 'category',
            operation: '~',
            value: categorySearchText
        });
    }

    if (parentCategory.length > 0) {
        categoryQuery.push({
            name: 'parent_category',
            operation: '|',
            value: parentCategory as Array<string>
        });
    }

    const { data: categories, isLoading: isCategoriesLoading } = useSWR<
        QueryReturnResult<{
            category: string;
            category_id: string;
            parent_category: string;
        }>
    >([CATEGORIES_QUERY_NAME, categoryQuery], searchableQuery);

    // Identify missing category IDs from URL parameters
    const fetchedCategoryIds = new Set((categories?.results || []).map((c) => c.category_id));
    const missingCategoryIds = category.filter((id) => !fetchedCategoryIds.has(id));

    // Fetch missing categories if there are any
    const { data: missingCategoriesData, isLoading: isMissingCategoriesLoading } = useSWR<
        QueryReturnResult<{
            category: string;
            category_id: string;
        }>
    >(missingCategoryIds.length > 0 ? ['missing_categories', missingCategoryIds] : null, () =>
        runQuery({
            name: CATEGORIES_QUERY_NAME,
            params: [
                {
                    name: 'category_id',
                    operation: '|',
                    value: missingCategoryIds
                }
            ]
        })
    );

    const categoryOptions = useMemo(() => {
        const options = getOptions(categories?.results, 'category');
        const missingOptions = getOptions(missingCategoriesData?.results, 'category');

        const optionsMap = new Map<string, { label: string; value: string }>();
        options.forEach((option) => {
            optionsMap.set(option.value, option);
        });

        missingOptions?.forEach((option) => {
            if (option && !optionsMap.has(option.value)) {
                optionsMap.set(option.value, option);
            }
        });

        return Array.from(optionsMap.values());
    }, [categories, missingCategoriesData]);

    const selectedCategoryOptions = useMemo(() => {
        return category.map((id) => categoryOptions.find((option) => option.value === id)).filter(Boolean) as Array<{
            label: string;
            value: string;
        }>;
    }, [category, categoryOptions]);

    const setSelectedCategories = (selectedOptions: Array<{ label: string; value: string }>) => {
        const categoryIds = selectedOptions.map((option) => option.value);
        setCategoryQueryParam(categoryIds);
    };

    const tagOptions = useMemo(() => getOptions(tagsData?.results, 'tag'), [tagsData]);
    const supplierOptions = useMemo(() => getOptions(suppliersData?.results, 'supplier'), [suppliersData]);
    const brandOptions = useMemo(() => getOptions(brands?.results, 'brand'), [brands]);

    const parentCategoryOptions = useMemo(() => getOptions(parentCategories?.results, 'parent_category'), [parentCategories]);

    const filters = useMemo(() => {
        const filters: Filter[] = [];
        addFilter(filters, 'tags', tags);
        addFilter(filters, 'supplier', suppliers);
        addFilter(filters, 'brand', brand);
        addFilter(filters, 'category_id', category);
        addFilter(filters, 'parent_category', parentCategory);
        return filters;
    }, [brand, category, parentCategory, tags, suppliers]);

    const hasFilters = brand.length + category.length + parentCategory.length + tags.length + suppliers.length > 0;

    return {
        isLoading:
            isSuppliersLoading ||
            isTagsLoading ||
            isBrandsLoading ||
            isCategoriesLoading ||
            isParentCategoriesLoading ||
            isMissingCategoriesLoading,
        brandOptions,
        categoryOptions,
        parentCategoryOptions,
        tagOptions,
        supplierOptions,
        filters,
        tags: filterNull(tags),
        suppliers: filterNull(suppliers),
        brand: filterNull(brand),
        category: filterNull(category),
        parentCategory: filterNull(parentCategory),
        setSuppliers,
        setTags,
        setBrand,
        setCategory: setCategoryQueryParam,
        setParentCategory,
        hasFilters,
        brandSearchText,
        setBrandSearchText,
        isBrandsLoading,
        supplierSearchText,
        setSupplierSearchText,
        isSuppliersLoading,
        tagSearchText,
        setTagSearchText,
        isTagsLoading,
        categorySearchText,
        setCategorySearchText,
        isCategoriesLoading,
        selectedCategoryOptions,
        setSelectedCategories
    };
};
