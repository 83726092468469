import { ProductList, ProductListWidget as ProductListWidgetType } from 'types';
import { ProductListCard } from './product-list-card';
import { useAverageProductImpactReport } from 'insights-explorer/use-average-product-impact';
import { displayRoundedValue } from 'utils/display-rounded-value';
import { useTranslation } from 'react-i18next';

export const ProductListWidget = ({ config }: { config: ProductListWidgetType }) => {
    const { averageProductImpactReportResult } = useAverageProductImpactReport();
    const { t } = useTranslation();
    const data:ProductList[] = [
        {
            label: t('unitOfCo2Emissions'),
            value: parseInt(displayRoundedValue(averageProductImpactReportResult?.data?.results[0]?.absolute_alop, {unit: ""})).toFixed(1),
            to: '/organization/{organizationId}/score-comparison/carbon_emissions',
            color: '#FEBF1F',
            icon: 'diesel_freight_train'
        },
        {
            label: t('unitOfWaterDepleted'),
            value: parseInt(displayRoundedValue(averageProductImpactReportResult?.data?.results[0]?.absolute_water_depletion, {unit: ""})).toFixed(1),
            to: '/organization/{organizationId}/score-comparison/water_depletion',
            color: '#487FEF',
            icon: 'oceanic_transport'
        },
        {
            label:t('unitOfLandUsed'),
            value: parseInt(displayRoundedValue(averageProductImpactReportResult?.data?.results[0]?.gwp_absolute, {unit: ""})).toFixed(1),
            to: '/organization/{organizationId}/score-comparison/land_use',
            color: 'rgb(71 204 164)',
            icon: 'medium_truck'
        }
    ];

    return <ProductListCard title={config.name} data={data} />;
};
