import { FileResponse } from 'data-management/data-ingestion/use-ingestion';
import useSWR from 'swr';
import { useMemo } from 'react';

const DEFAULT_LIMIT = 1000;

export const useFileSelectOptions = () => {
    const { data, isLoading } = useSWR<FileResponse>([
        `/ingest/files/query`,
        {
            limit: DEFAULT_LIMIT
        }
    ]);

    const options = useMemo(() => {
        if (!data) return [];
        return data.items.map((file) => ({
            label: file.name,
            value: file.id
        }));
    }, [data]);

    return {
        options,
        isLoading
    };
};
