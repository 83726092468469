import { useOrganizationId } from 'shared/use-organization-id';
import { useUser } from 'shared/use-user';
import useSWR from 'swr';
import { QueryResult, User } from 'types';
import { request } from 'lib/request-client';

interface Impersonation {
    deleted: boolean;
    user_id: string;
    namespace_id: string;
    created_on: string;
    updated_on: string;
    id: string;
    impersonated_user_id: string;
}

export const useNamespaceUsers = (namespace: string | null) => {
    const { activeUser } = useUser();
    const organizationId = useOrganizationId();
    const {
        data: users,
        error: usersError,
        isLoading: isUsersLoading
    } = useSWR<User[]>(
        namespace ? `/_api/internal/namespace/${namespace}/users/list` : null,
        (url: string) => request.url(url, true).get() as Promise<User[]>
    );

    const {
        data: impersonations,
        isLoading: isImpersonationLoading,
        mutate: mutateImpersonations,
        error: impersonationsError
    } = useSWR<QueryResult<Impersonation>>(
        namespace ? `/_api/internal/impersonations/query?namespace_id=${namespace}` : null,
        (url: string) => request.url(url, true).get() as Promise<QueryResult<Impersonation>>
    );

    const createUserImpersonation = async ({
        impersonated_user_id,
        namespace_id
    }: {
        impersonated_user_id: string;
        namespace_id: string;
    }) => {
        if (!activeUser) return;
        const result = await request.url(`/_api/internal/impersonation/create`, true).post({
            user_id: activeUser.id,
            impersonated_user_id,
            namespace_id
        });
        await mutateImpersonations();
        return result;
    };

    const deleteUserImpersonation = async (params: { namespace_id: string; impersonated_user_id: string; user_id: string }) => {
        const result = await request.url(`/_api/internal/impersonation`, true).query(params).delete();
        await mutateImpersonations(undefined, true);
        return result;
    };

    return {
        users: users ?? [],
        usersError,
        impersonationsError,
        isUsersLoading,
        createUserImpersonation,
        deleteUserImpersonation,
        impersonations,
        isImpersonationLoading,
        isLoading: isImpersonationLoading || isUsersLoading
    };
};
