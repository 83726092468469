import { ErrorCard } from 'shared/error-card';
import ScoreCircleGraph from 'shared/score-circle-graph';
import { ScoreGauge } from 'shared/score-gauge';
import { Section } from 'shared/section';
import { useFeatureEnabled } from 'shared/use-feature-enabled';
import { useParams } from 'react-router-dom';
import { Group, PaperProps, Skeleton, Stack, Text, Title, rem } from '@mantine/core';
import { FlagName } from 'utils/get-flag-value';
import { DataQualityCard } from './data-quality-card';
import classes from './livelihoods-and-wellbeing-cards.module.css';
import { NoDataAlert } from './no-data-alert';
import { useLivelihoodsAndWellbeing } from './use-livelihoods-and-wellbeing';
import { useScoreReport } from './use-score-report';

export const LivelihoodsAndWellbeingCards = (props: PaperProps) => {
    const { isEnabled } = useFeatureEnabled();
    const { id } = useParams<{ id: string }>();
    const { isLoading, data, error } = useLivelihoodsAndWellbeing(id);

    const { dqr } = useScoreReport(id);
    const livelihoodsAndWellbeingDqr = dqr?.data?.results[0]?.livelihoods_and_wellbeing_dqr;

    const firstResult = data?.results[0];
    const hasNoData = !isLoading && !firstResult;

    if (error) {
        return <ErrorCard error={error} />;
    }

    return (
        <>
            {hasNoData ? (
                <NoDataAlert />
            ) : (
                <>
                    <Section {...props}>
                        <Group align="center" justify="space-between" gap="xs">
                            <Stack gap={rem(4)} className={classes.introduction}>
                                <Title size="h2">Livelihoods & Wellbeing</Title>
                                <Text c="dimmed" size="sm">
                                    Livelihoods & Wellbeing takes a risk-based approach to detect where supply chain activities locations
                                    with may have the highest likelihood of worker rights abuses or harms to worker wellbeing. The score
                                    considers guarantees from eco-labels and certifications that apply to the product and carry relevant
                                    labor assurances, plus any direct assurances provided as primary data. The analysis is supported by
                                    datasets from the International Labour Organization (ILO), UNICEF, and the World Bank to assess
                                    labor-related risks in specific countries and industry sectors. This dimension focuses on human and
                                    worker rights in the supply chain only.
                                </Text>
                            </Stack>

                            {!isLoading && (
                                <ScoreCircleGraph
                                    peerPercentage={firstResult?.category_average ?? 0}
                                    percentage={firstResult?.total ?? 0}
                                    size="md"
                                    className={classes.scoreCircle}
                                    bottom={
                                        <Text size="sm" fw={500}>
                                            Category average: {firstResult?.category_average ?? 0}
                                        </Text>
                                    }
                                />
                            )}
                        </Group>
                    </Section>

                    <Section mt="md" {...props}>
                        <Title size="h4" mb={rem(4)}>
                            People Impact
                        </Title>
                        <Text c="dimmed" size="sm">
                            This is the level of risk of abuse in three different areas of your supply chain. Low risk means that the supply
                            chain of your product is, based on the data provided, not likely to contribute to misconduct in the workplace.
                            High risk signals that there are known issues related to the supply of your products in the areas from where you
                            are sourcing. Implementation of guidelines, procedures and compliance checks to assure the safety of workers
                            across the supply chain will lower the product's risk.
                        </Text>

                        {isLoading ? (
                            <Skeleton height={180} mt="lg" data-testid="loading-people-impact" />
                        ) : (
                            <Group
                                mt={rem(40)}
                                gap="xl"
                                style={{ justifyContent: 'space-evenly', alignItems: 'flex-start', marginBottom: rem(20) }}
                            >
                                <Stack gap="xs" align="center" justify="center">
                                    <ScoreGauge inverted score={firstResult?.working_conditions ?? 0} />
                                    <Text size="lg" fw={700} ta="center">
                                        Working conditions
                                    </Text>

                                    <Text c="dimmed" size="sm" ta="center" maw={rem(300)}>
                                        Working conditions are assessed by investigating the amount of working hours and possibility of
                                        collective bargaining
                                    </Text>
                                </Stack>

                                <Stack gap="xs" align="center" justify="center">
                                    <ScoreGauge inverted score={firstResult?.workers_protection ?? 0} />
                                    <Text size="lg" fw={700} ta="center">
                                        Workers protection
                                    </Text>

                                    <Text c="dimmed" size="sm" ta="center" maw={rem(300)}>
                                        Workers protection assesses the risk of youth labour and how the health and safety standards are
                                        observed throughout the supply chain.
                                    </Text>
                                </Stack>

                                <Stack gap="xs" align="center" justify="center">
                                    <ScoreGauge inverted score={firstResult?.fair_pay ?? 0} />
                                    <Text size="lg" fw={700} ta="center">
                                        Fair Pay
                                    </Text>

                                    <Text c="dimmed" size="sm" ta="center" maw={rem(300)}>
                                        Fair pay assesses the application of living wages and minimizing the gender pay gap.
                                    </Text>
                                </Stack>
                            </Group>
                        )}
                    </Section>

                    <Section mt="md" {...props}>
                        <Title size="h4" mb={rem(4)}>
                            Transparency
                        </Title>
                        <Text c="dimmed" size="sm">
                            Transparency evaluates the quality of primary data that was available for the Working Conditions, Worker
                            Protection, and Fair Pay risk assessments. about supply chain livelihoods & wellbeing risks and mitigations for
                            the products. The more specific and rigorous the data provided, the better the overall transparency score.
                            Company- and product-specific labor policy documents and, factory audits are considered to be higher quality
                            than eco-labels, which in turn are superior to a total lack of primary data.
                        </Text>

                        {isLoading ? (
                            <Skeleton height={180} mt="lg" />
                        ) : (
                            <Group justify="center" mt="xl">
                                <ScoreCircleGraph
                                    peerTrailColor="#fff"
                                    percentage={firstResult?.transparency ?? 0}
                                    peerPercentage={0}
                                    size="lg"
                                />
                            </Group>
                        )}
                    </Section>
                    {isEnabled(FlagName.DataQualityRating) && (
                        <Section mt="md" {...props}>
                            <DataQualityCard score={livelihoodsAndWellbeingDqr ?? undefined} />
                        </Section>
                    )}
                    <Text c="dimmed" fs="italic" size="sm" my="lg">
                        We look at the best available data to determine risk across these 4 aspects of your supply chain. However, it is
                        important to note that the best guarantee of accurate livelihoods & wellbeing data is through independent, third
                        party audits of labor conditions across your supply chain. Having data from a third party audit, certification or
                        ecolabel would also improve your score by lowering the amount of risk we assign to your data.
                    </Text>
                </>
            )}
        </>
    );
};
