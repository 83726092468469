import { ErrorAlert } from 'shared/error-alert';
import { Section } from 'shared/section';
import { StaticTable } from 'shared/static-table';
import { ValueWidget as ValueWidgetType } from 'types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Title, rem } from '@mantine/core';
import { displayRoundedValue } from 'utils/display-rounded-value';
import { useMostImpactfulInsightsReport } from './use-most-impactful-insights';

export const MostImpactfulMaterials = ({ config }: { config: ValueWidgetType }) => {
    const { t } = useTranslation();
    const { mostImpactfulInsightsResult } = useMostImpactfulInsightsReport();
    const navigate = useNavigate();
    // Modify handleClick to navigate with tab=3 parameter
    const handleClick = () => {
        if (config?.link) {
            navigate(`${config.link}?tab=${t('mostImpactfulMaterials')}`); // Append tab=3 to the URL
        }
    };
    return (
        <button
            onClick={handleClick}
            style={{
                background: 'none',
                border: 'none',
                padding: 0,
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                justifyContent: 'space-between'
            }}
        >
            <Section mb="md">
                <Title style={{ textAlign: 'left' }} size="h4">
                    {t('mostImpactfulMaterials')}
                </Title>

                {mostImpactfulInsightsResult.error ? (
                    <ErrorAlert error={mostImpactfulInsightsResult.error} />
                ) : (
                    <StaticTable
                        showMoreLimit={10}
                        isLoading={mostImpactfulInsightsResult.isLoading}
                        m={`${rem(32)} 0`}
                        columns={[
                            {
                                key: 'beautified_name_with_characteristic',
                                name: t('impactfulMaterial'),
                                sortable: true
                            },
                            {
                                key: 'absolute_co2_emissions',
                                name: t('absoluteCo2Emissions'),
                                sortable: true,
                                render: displayRoundedValue
                            },
                            {
                                key: 'absolute_water_depletion',
                                name: t('absoluteWaterDepletion'),
                                sortable: true,
                                render: displayRoundedValue,
                                width: 180
                            },
                            {
                                key: 'absolute_land_use',
                                name: t('absoluteLandUse'),
                                sortable: true,
                                render: displayRoundedValue,
                                width: 180
                            }
                        ]}
                        rowKey={(row) =>
                            `${row.beautified_name_with_characteristic}-${row.absolute_co2_emissions}-${row.absolute_water_depletion}`
                        }
                        data={mostImpactfulInsightsResult.data?.results ?? []}
                    />
                )}
            </Section>
        </button>
    );
};
