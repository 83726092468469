import useSWR from 'swr';
import { request } from 'lib/request-client';

interface EmbedResponse {
    signedUrl: string;
}

export const useSignedUrl = (dashboardId: number, theme: string, embedDomain: string) => {
    const { data, error, mutate, isLoading } = useSWR<EmbedResponse>(
        ['/looker/embed/signed-url', dashboardId, theme],
        () =>
            request
                .url(`/_api/internal/looker/embed/signed-url`, true)
                .post({ dashboard_id: dashboardId, theme: theme, embed_domain: embedDomain }) as Promise<EmbedResponse>
    );
    return { data, error, mutate, isLoading };
};
