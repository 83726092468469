import { t } from 'i18next';
import { DownloadButton } from 'shared/download-button';
import { Section } from 'shared/section';
import { ActionableInsight, ActionableInsightWidget } from 'types';
import { useState } from 'react';
import { Group, Box, Title, Text, Grid } from '@mantine/core';
import { createInsightData } from './create-insight-data';
import { InsightCard } from './insight-card';
import { useActionableInsightsReport } from './use-actionable-insights-report';

export const ActionableInsights = () => {
    const [dimension] = useState('Insights');
    const { actionableInsightsResult } = useActionableInsightsReport();

    const data = actionableInsightsResult?.data?.results.map((item) => ({
        widgets: [createInsightData(item)]
    }));

    return (
        <>
            <Group justify="space-between" align="center" mb="sm">
                <Box>
                    <Title size="h3">{t('actionableInsights')}</Title>
                    <Text c="dimmed" size="sm">
                        {t('actionableInsightsDescription')}
                    </Text>
                </Box>
                <DownloadButton
                    exports={[
                        {
                            name: `${dimension}_actionable`,
                            data: data
                        }
                    ]}
                    disabled={!data}
                />
            </Group>

            <Section>
                <Title size="h4" style={{ margin: '10px' }}>
                    {t('climateImpact')}
                </Title>
                <Grid align="stretch" mb="md" gutter="sm">
                    {data?.map((widget: ActionableInsight) =>
                        widget.widgets.map((item: ActionableInsightWidget) => (
                            <Grid.Col key={item.name} span={{ md: 6, sm: 12 }}>
                                <InsightCard name={item.name} newName={item.newName} data={item.insightData} link={''} title={''}/>
                            </Grid.Col>
                        ))
                    )}
                </Grid>
            </Section>
        </>
    );
};
