import { ruleConfigCategories, useRuleConfig } from 'data-management/rule-config/use-rule-config';
import { paths } from 'paths';
import { Section } from 'shared/section';
import { StaticTable } from 'shared/static-table';
import { useUser } from 'shared/use-user';
import { Rule, RuleConfigCategoryType, RuleConfigInput, RuleConfigUpdate } from 'types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbDotsVertical } from 'react-icons/tb';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    ActionIcon,
    Anchor,
    Breadcrumbs,
    Button,
    Code,
    Group,
    Menu,
    Select,
    Skeleton,
    Stack,
    Text,
    TextInput,
    Textarea,
    rem
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showErrorMessage } from 'utils/show-error-message';
import { showSuccessMessage } from 'utils/show-success-message';
import { validation } from 'utils/validation';
import { RuleModal } from './rule-modal';

export const RuleConfigDetail = () => {
    const { t } = useTranslation();

    const [isSubmitting, setSubmitting] = useState(false);
    const { organization } = useUser();
    const [showRuleModal, setShowRuleModal] = useState(false);
    const { organizationId, id } = useParams<{ organizationId: string; id: string }>();
    const isCreate = id === 'create';

    const navigate = useNavigate();

    const { data, isLoading, create, update, mutate } = useRuleConfig(isCreate ? undefined : id);
    const [initialRules, setInitialRules] = useState<Rule[]>([]);

    const form = useForm({
        initialValues: {
            name: '',
            description: '',
            category: ruleConfigCategories[0] as RuleConfigCategoryType,
            rules: [] as Rule[]
        },

        validate: {
            name: validation.required
        }
    });

    useEffect(() => {
        if (data && !isCreate) {
            const values = {
                name: data.name ?? '',
                description: data.description ?? '',
                rules: data.rules ?? [],
                category: data.category ?? ruleConfigCategories[0]
            };
            form.setValues(values);
            form.resetDirty(values);
            setInitialRules(data.rules ?? []);
        }
    }, [data, isCreate]);

    const handleSubmit = form.onSubmit(async (values) => {
        try {
            if (!organization) return;

            setSubmitting(true);

            if (isCreate) {
                let createBody: RuleConfigInput = {
                    organization_id: organization.id,
                    name: values.name,
                    description: values.description,
                    category: values.category
                };

                createBody.rules = form.values.rules;
                await create(createBody);
            } else {
                // For update, compute the added and removed rules
                const initialRuleCodeNames = new Set(initialRules.map((rule) => rule.code_name));
                const currentRuleCodeNames = new Set(form.values.rules.map((rule) => rule.code_name));

                const rules_add = form.values.rules.filter((rule) => !initialRuleCodeNames.has(rule.code_name));

                const rules_remove = initialRules
                    .filter((rule) => rule.code_name !== undefined && !currentRuleCodeNames.has(rule.code_name))
                    .map((rule) => rule.id as string);

                let updateBody: RuleConfigUpdate = {
                    name: values.name,
                    description: values.description,
                    category: values.category
                };

                updateBody.rules_add = rules_add;
                updateBody.rules_remove = rules_remove;

                await update(updateBody);
            }

            navigate(paths.dataManagement.ruleConfig(organizationId));
            mutate();
            showSuccessMessage(t('ruleConfigSaved'));
        } catch (error) {
            showErrorMessage(error);
        } finally {
            setSubmitting(false);
        }
    });

    return (
        <>
            <Breadcrumbs mb={rem(16)}>
                <Anchor component={Link} to={`/organization/${organizationId}/rule-config`}>
                    {t('ruleConfig')}
                </Anchor>
                <Text>{isCreate ? t('create') : data?.name ?? '-'}</Text>
            </Breadcrumbs>

            <Section>
                {!isCreate && isLoading ? (
                    <Skeleton height={300} />
                ) : (
                    <form onSubmit={handleSubmit}>
                        <Stack gap="xs" maw={420}>
                            <TextInput withAsterisk label={t('name')} {...form.getInputProps('name')} />
                            <Textarea label={t('description')} autosize minRows={3} {...form.getInputProps('description')} />
                            <Select
                                label={t('category')}
                                comboboxProps={{ position: 'bottom', withinPortal: true }}
                                withAsterisk
                                data={ruleConfigCategories}
                                {...form.getInputProps('category')}
                            />
                        </Stack>
                        <Stack mt={rem(20)} gap="xs">
                            <Text size="lg" fw={500}>
                                {t('rules')}
                            </Text>
                            <StaticTable
                                actions={
                                    <Button variant="outline" onClick={() => setShowRuleModal(true)}>
                                        {t('addRule')}
                                    </Button>
                                }
                                columns={[
                                    {
                                        key: 'order',
                                        name: t('order'),
                                        render: (_, __, index) => index + 1
                                    },
                                    {
                                        key: 'name',
                                        name: t('name')
                                    },
                                    {
                                        key: 'description',
                                        name: t('description')
                                    },
                                    {
                                        key: 'type',
                                        name: t('type'),
                                        render: (value) => <Code>{value}</Code>
                                    },
                                    {
                                        key: 'code_name',
                                        name: t('code'),
                                        render: (value) => <Code>{value}</Code>
                                    },
                                    {
                                        key: 'actions',
                                        name: '',
                                        render: (_, row) => {
                                            return (
                                                <Menu shadow="md">
                                                    <Menu.Target>
                                                        <ActionIcon data-testid={row.code_name + '-action'}>
                                                            <TbDotsVertical size={16} />
                                                        </ActionIcon>
                                                    </Menu.Target>
                                                    <Menu.Dropdown>
                                                        <Menu.Item
                                                            color="red"
                                                            onClick={() => {
                                                                form.setFieldValue(
                                                                    'rules',
                                                                    form.values.rules.filter(
                                                                        (current) => current.code_name !== row.code_name
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            {t('remove')}
                                                        </Menu.Item>
                                                    </Menu.Dropdown>
                                                </Menu>
                                            );
                                        }
                                    }
                                ]}
                                isLoading={isLoading}
                                data={form.values.rules}
                            />
                        </Stack>
                        <Group justify="flex-end" mt="xl" gap="sm">
                            <Button loading={isSubmitting} type="submit">
                                {isCreate ? t('createRuleConfig') : t('updateRuleConfig')}
                            </Button>
                        </Group>
                    </form>
                )}
            </Section>

            <RuleModal
                currentRules={form.values.rules}
                isOpen={showRuleModal}
                onClose={() => setShowRuleModal(false)}
                onSubmit={(newRule) => {
                    form.setFieldValue('rules', [...form.values.rules, newRule]);
                }}
            />
        </>
    );
};
