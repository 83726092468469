import { ValueWidget as ValueWidgetType, Nullable } from 'types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Tabs } from '@mantine/core';
import { CircularityOfMaterials } from './circularity-of-materials';
import { ClimateImpactsOfMaterials } from './climate-impacts-of-materials';
import { EcosystemImpactsOfMaterials } from './ecosystem-impacts-of-materials';
import { MostImpactfulMaterials } from './most-impactful-materials';

export const MaterialInsights = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams(); // Get and set query parameters
    const tab = searchParams.get('tab'); // Get the 'tab' query param

    const tabs = [
        t('climateImpactsOfMaterials'),
        t('circularityOfMaterials'),
        t('ecosystemImpactsOfMaterials'),
        t('mostImpactfulMaterials')
    ];

    // Set the default active tab to the query param or fallback to the first tab
    const [activeTab, setActiveTab] = useState<Nullable<string>>(tabs[0]);

    useEffect(() => {
        // Update active tab if query param changes
        if (tab) {
            const selTab = tabs.find((t) => t === tab);
            if (selTab) {
                setActiveTab(tab);
            }
        }
    }, [tab, tabs]);

    const handleTabChange = (newTab: any) => {
        setActiveTab(newTab); // Update the active tab
        setSearchParams({ tab: newTab }); // Update the query parameter in the URL
    };

    return (
        <Tabs value={activeTab} onChange={handleTabChange} variant="pills">
            <Tabs.List mb="lg">
                {tabs.map((tab) => (
                    <Tabs.Tab key={tab} value={tab}>
                        {tab}
                    </Tabs.Tab>
                ))}
            </Tabs.List>

            <Tabs.Panel value={t('climateImpactsOfMaterials')}>
                <ClimateImpactsOfMaterials />
            </Tabs.Panel>
            <Tabs.Panel value={t('circularityOfMaterials')}>
                <CircularityOfMaterials config={undefined as unknown as ValueWidgetType} />
            </Tabs.Panel>
            <Tabs.Panel value={t('ecosystemImpactsOfMaterials')}>
                <EcosystemImpactsOfMaterials />
            </Tabs.Panel>
            <Tabs.Panel value={t('mostImpactfulMaterials')}>
                <MostImpactfulMaterials config={undefined as unknown as ValueWidgetType} />
            </Tabs.Panel>
        </Tabs>
    );
};
