import { CircularityOfMaterials } from 'insights-explorer/circularity-of-materials';
import { DataQualityRating } from 'insights-explorer/dataQualityRating';
import { MostImpactfulMaterials } from 'insights-explorer/most-impactful-materials';
import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import { useFeatureEnabled } from 'shared/use-feature-enabled';
import { useOrganizationId } from 'shared/use-organization-id';
import {
    BarChartWidget as BarChartWidgetType,
    Dashboard,
    ValueWidget as ValueWidgetType,
    ListWidget as ListWidgetType,
    ProductListWidget as ProductListWidgetType
} from 'types';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { Grid, Group, Text, Title, rem } from '@mantine/core';
import { FlagName } from 'utils/get-flag-value';
import { BarChartWidget } from './bar-chart-widget';
import { InsightWidget } from './insight-widget';
import { ListWidget } from './list-widget';
import { ProductListWidget } from './product-list-widget';
import { DEFAULT_RANGE_OPTION } from './range-select';
import { ValueWidget } from './value-widget';

export const DashboardConfiguration = ({ data }: { data: Dashboard }) => {
    const [range] = useQueryParam('range', withDefault(StringParam, DEFAULT_RANGE_OPTION));
    const organizationId = useOrganizationId() || '';
    const { isEnabled } = useFeatureEnabled();
    const widgets = data.widgets.map((widget) => {
        const updatedWidget = { ...widget };

        if (updatedWidget.link) {
            updatedWidget.link = updatedWidget.link.replaceAll('{organizationId}', organizationId);
        }

        return updatedWidget;
    });

    return (
        <>
            <Group gap={rem(2)} mb="md">
                <Title>{data.name}</Title>

                {data.description && (
                    <MoreInfoHoverCard>
                        <Text size="sm" c="dimmed">
                            {data.description}
                        </Text>
                    </MoreInfoHoverCard>
                )}
            </Group>

            <Grid>
                {widgets.map((widget) => {
                    if (widget.type === 'value')
                        return (
                            <Grid.Col key={widget.name} span={{ md: widget.span, sm: 12 }}>
                                <ValueWidget range={range} config={widget as ValueWidgetType} />
                            </Grid.Col>
                        );

                    if (widget.type === 'list') {
                        return (
                            <Grid.Col key={widget.name} span={{ md: widget.span, sm: 12 }}>
                                <ListWidget range={range} config={widget as ListWidgetType} />
                            </Grid.Col>
                        );
                    }

                    if (widget.type === 'product-list') {
                        return (
                            <Grid.Col key={widget.name} span={{ md: widget.span, sm: 12 }}>
                                <ProductListWidget range={range} config={widget as ProductListWidgetType} />
                            </Grid.Col>
                        );
                    }

                    if (widget.type === 'data-quality' && isEnabled(FlagName.DataQualityRating)) {
                        return (
                            <Grid.Col key={widget.name} span={{ md: widget.span, sm: 12 }}>
                                <DataQualityRating config={widget as ValueWidgetType} />
                            </Grid.Col>
                        );
                    }

                    if (widget.type === 'insight-circularity-widget') {
                        return (
                            <Grid.Col key={widget.name} span={{ md: widget.span, sm: 12 }}>
                                <CircularityOfMaterials config={widget as ValueWidgetType} />
                            </Grid.Col>
                        );
                    }

                    if (widget.type === 'insight-widget' && widget.render) {
                        return (
                            <Grid.Col key={widget.name} span={{ md: widget.span, sm: 12 }}>
                                <InsightWidget range={range} config={widget as ValueWidgetType} />
                            </Grid.Col>
                        );
                    }

                    if (widget.type === 'most-impactful-materials') {
                        return (
                            <Grid.Col key={widget.name} span={{ md: widget.span, sm: 12 }}>
                                <MostImpactfulMaterials config={widget as ValueWidgetType} />
                            </Grid.Col>
                        );
                    }

                    if (widget.type === 'bar-chart') {
                        return (
                            <Grid.Col key={widget.name} span={{ md: widget.span, sm: 12 }}>
                                <BarChartWidget range={range} config={widget as BarChartWidgetType} />
                            </Grid.Col>
                        );
                    }

                    return null;
                })}
            </Grid>
        </>
    );
};
