import LookerDashboard from 'shared/looker-dashboard';
import { Section } from 'shared/section';
import { useTranslation } from 'react-i18next';
import { Box, Group, Title } from '@mantine/core';

export const ProductComparisonDashboard = () => {
    const PRODUCT_COMPARISON_DASHBOARD_ID = 17;
    const { t } = useTranslation();
    return (
        <>
            {' '}
            <Group justify="space-between" align="center" mb="sm">
                <Box>
                    <Title size="h3">{t('productComparison')}</Title>
                </Box>
            </Group>
            <Section style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <LookerDashboard dashboardId={PRODUCT_COMPARISON_DASHBOARD_ID} style={{ flexGrow: 1 }} />
            </Section>
        </>
    );
};
