import { useOrganizationId } from 'shared/use-organization-id';
import useSWR from 'swr';
import { GroupWithUsers, Group, GroupUpdate } from 'types';
import { request } from 'lib/request-client';

export const useGroup = (id?: string) => {
    const organizationId = useOrganizationId();
    const { data, error, mutate, isLoading } = useSWR<GroupWithUsers>(id ? `/account_manager/group/${id}` : null);

    const update = async (body: GroupUpdate) => {
        await request.url(`/account_manager/group/${id}`).patch({
            ...body,
            organization_id: `${organizationId}`
        });
        mutate();
    };

    /* This is a temporary hack to allow update to be called immediately after the create action 
        and update the user list. Accepts groupId as a parameter that is returned from the create api response. 
    */
    const updateGroup = async (groupId:string, body: GroupUpdate) => {
        await request.url(`/account_manager/group/${groupId}`).patch({
            ...body,
            organization_id: `${organizationId}`
        });
        mutate();
    };


    const create = async (body: Partial<Group>): Promise<Group> => {
        const response = await request.url(`/account_manager/group/create`).post({
            ...body,
            organization_id: `${organizationId}`
        });
        mutate();
        return response as Group
    };

    return { data, error, mutate, isLoading, update, create, updateGroup };
};
