import { useOrganization } from 'account-management/organizations/use-organization';
import { useOrganizationUsers } from 'account-management/users/use-organization-users';
import { links } from 'links';
import { ErrorCard } from 'shared/error-card';
import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import { Section } from 'shared/section';
import { Table } from 'shared/table';
import { useOrganizationId } from 'shared/use-organization-id';
import { useUser } from 'shared/use-user';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbDotsVertical } from 'react-icons/tb';
import { ActionIcon, Anchor, Avatar, Button, Group, Menu, Text, Title, rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { getDateColumns } from 'utils/get-date-columns';
import { getErrorMessage } from 'utils/get-error-message';
import { AddToWhitelistModal } from './add-to-whitelist-modal';

export const Users = () => {
    const { t } = useTranslation();
    const [showAddToWhitelistModal, setShowAddToWhitelistModal] = useState(false);
    const organizationId = useOrganizationId();
    const { activeUser, hasPermission } = useUser();
    const { removeFromWhitelist, data: organization } = useOrganization(
        hasPermission('/account_manager/organizations/query') ? organizationId : undefined
    );
    const { data, error, isLoading, mutate, page, onPage, order, onOrderChange, search, onSearchChange, limit, onLimitChange } =
        useOrganizationUsers();

    if (error) {
        return <ErrorCard error={error} />;
    }

    const handleRemoveFromWhitelist = async (email: string) => {
        notifications.show({
            id: 'whitelist-remove',
            loading: true,
            message: t('removingFromWhitelist'),
            autoClose: false,
            withCloseButton: false
        });

        try {
            await removeFromWhitelist(email);
            notifications.update({
                id: 'whitelist-remove',
                color: 'green',
                message: t('removedSuccessfully'),
                loading: false
            });
            mutate();
        } catch (error) {
            notifications.update({
                id: 'whitelist-remove',
                message: getErrorMessage(error),
                color: 'red',
                loading: false
            });
        }
    };

    return (
        <>
            <Group gap={rem(2)}>
                <Title size="h3">{t('users')}</Title>

                <MoreInfoHoverCard>
                    <Text size="sm" c="dimmed">
                        Allows you to manage the{' '}
                        <Anchor target="_blank" href={links.customerService.userWhitelisting}>
                            whitelisted users
                        </Anchor>{' '}
                        of your organization.
                    </Text>
                </MoreInfoHoverCard>
            </Group>

            <Text c="dimmed" mb="md">
                {t('usersDescription')}
            </Text>

            <Section>
                <Table
                    actions={
                        hasPermission('/account_manager/organization/organization', 'PATCH') && (
                            <Button onClick={() => setShowAddToWhitelistModal(true)}>{t('addUser')}</Button>
                        )
                    }
                    isLoading={isLoading}
                    data={data?.items}
                    rowKey={(row) => row.email}
                    columns={[
                        ...(import.meta.env.DEV
                            ? [
                                  {
                                      key: 'id',
                                      name: 'ID'
                                  }
                              ]
                            : []),
                        {
                            key: 'name',
                            name: t('name'),
                            sortable: true,
                            render: (value, row) => (
                                <Group wrap="nowrap">
                                    <Avatar radius="xl" src={row.photo_url} size="sm" />
                                    <span>{value}</span>
                                </Group>
                            )
                        },
                        {
                            key: 'email',
                            name: t('email'),
                            sortable: true
                        },
                        ...getDateColumns(),
                        {
                            key: 'actions',
                            name: '',
                            align: 'right',
                            render: (_, row) => (
                                <>
                                    {activeUser?.email !== row.email &&
                                        hasPermission('/account_manager/organization/organization', 'PATCH') && (
                                            <Menu shadow="md">
                                                <Menu.Target>
                                                    <ActionIcon data-testid={`menu-${row.id}`}>
                                                        <TbDotsVertical size={16} />
                                                    </ActionIcon>
                                                </Menu.Target>

                                                <Menu.Dropdown>
                                                    <Menu.Item color="red" onClick={() => handleRemoveFromWhitelist(row.email)}>
                                                        {t('remove')}
                                                    </Menu.Item>
                                                </Menu.Dropdown>
                                            </Menu>
                                        )}
                                </>
                            )
                        }
                    ]}
                    search={search}
                    onSearchChange={onSearchChange}
                    limit={limit}
                    onLimitChange={onLimitChange}
                    page={page}
                    onPage={onPage}
                    order={order}
                    onOrderChange={onOrderChange}
                />
            </Section>

            <AddToWhitelistModal
                isOpen={showAddToWhitelistModal}
                organizationId={organizationId}
                currentWhitelist={organization?.user_whitelist?.map((user) => user.email) ?? []}
                onComplete={() => mutate()}
                onClose={() => setShowAddToWhitelistModal(false)}
            />
        </>
    );
};
