import { NAMESPACE_STORAGE_KEY, useAuth } from 'account-management/auth/use-auth';
import { isTest } from 'env';
import { setUserId, setUserProperties } from 'firebase/analytics';
import useSWR from 'swr';
import { Organization, Permission, RoleType, User } from 'types';
import { useEffect } from 'react';
import { analytics } from 'lib/firebase';

const DEFAULT_DOMAINS = ['dayrize.com', 'ecocorp.nl'];
const DAYRIZE_AUTH_DOMAINS = isTest ? [...DEFAULT_DOMAINS, 'arc.inc'] : DEFAULT_DOMAINS;

export const roleMap: { [role in RoleType]: Array<string> } = {
    Owner: ['POST', 'PUT', 'GET', 'PATCH', 'DELETE'],
    Editor: ['GET', 'PATCH'],
    Viewer: ['GET']
};

export const useUser = () => {
    const { user: firebaseUser, signOut } = useAuth();
    const { data, error, isLoading, mutate } = useSWR<{
        user: User;
        organization: Organization;
        permissions: Permission[];
        available_namespaces: {
            name: string;
            namespace_id: string;
        }[];
    }>(firebaseUser ? [`/account_manager/user/me`, firebaseUser.uid] : null, {
        revalidateIfStale: false
    });

    const isOwner = data?.permissions.some((permission) => permission.role === 'Owner');
    const isDayrize = data?.organization.auth_domain && DAYRIZE_AUTH_DOMAINS.includes(data.organization.auth_domain);
    const isDayrizeImpersonating =
        !!window.sessionStorage.getItem(NAMESPACE_STORAGE_KEY) && DAYRIZE_AUTH_DOMAINS.includes(firebaseUser?.email?.split('@')[1] ?? '');

    useEffect(() => {
        if (isDayrize) setUserProperties(analytics, { is_internal_user: true });
    }, [isDayrize]);

    useEffect(() => {
        if (firebaseUser) setUserId(analytics, firebaseUser.uid);
    }, [firebaseUser]);

    return {
        isLoading,
        error,
        isOwner,
        isDayrize,
        isDayrizeImpersonating,
        firebaseUser,
        mutate,
        activeUser: data?.user,
        permissions: data?.permissions,
        organization: data?.organization,
        namespaces: data?.available_namespaces ?? [],
        hasPermission: (permission: string, method = 'GET') =>
            data?.permissions.some((current) => {
                const matchesResource = permission.startsWith(current.resource);
                const matchesRole = current.role && roleMap[current.role]?.includes(method);
                return matchesResource && matchesRole;
            }),
        onLogout: async () => {
            await signOut();
            window.sessionStorage.clear();
            mutate(undefined, false);
        }
    };
};
