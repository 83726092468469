import { CircularityProductResult } from 'products/use-circularity';
import { ErrorAlert } from 'shared/error-alert';
import { formatEol } from 'shared/format-eol';
import { GraphLegend } from 'shared/graph-legend';
import ScoreCircleGraph from 'shared/score-circle-graph';
import ScoreColorLegend from 'shared/score-color-legend';
import { ScoreReportBarChart } from 'shared/score-report-bar-chart';
import { Section } from 'shared/section';
import { useBiobasedMaterialsMapping } from 'shared/use-biobased-materials-mapping';
import { useEolMapping } from 'shared/use-eol-mapping';
import { useFeatureEnabled } from 'shared/use-feature-enabled';
import { useRecycledOrReusedMaterialsMapping } from 'shared/use-recycled-or-reused-materials-mapping';
import { WaffleGraph } from 'shared/waffle-graph';
import { Dimension } from 'types/shared';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Group, PaperProps, Skeleton, Stack, Text, Title, rem } from '@mantine/core';
import { FlagName } from 'utils/get-flag-value';
import { sortByKey } from 'utils/sort-by-key';
import { toPercentage } from 'utils/to-percentage';
import classes from './circularity-cards.module.css';
import { DataQualityCard } from './data-quality-card';
import { useScoreReport } from './use-score-report';

const getProductClassification = (result?: CircularityProductResult) => {
    if (!result) return null;
    const mapping = {
        durable: {
            title: 'Durable',
            description:
                "We define 'Durable' products as products that are used repeatedly or continuously over a period longer than a year, such as furniture, jewellery, or sporting goods. Product makes up 80% of the products circularity score, and packaging makes up 20% because the product is classified as Durable."
        },
        'semi-durable': {
            title: 'Semi durable',
            description:
                "We define 'Semi-Durable' products as products that are used repeatedly or continuously over a period longer than a year. Its expected lifetime of use, though longer than a year, is often significantly shorter and that it's purchasers' price is substantially less than durable. E.g. apparel, footwear, home textiles. Product makes up 70% of the products circularity score, and packaging makes up 30% because the product is classified as Semi-Durable"
        },
        'non-durable': {
            title: 'Non-durable',
            description:
                "We define 'Non-Durable' products as products that are used repeatedly or continuously over a period less than a year, assuming a normal or average rate of physical usage. E.g. razors, toothbrushes, hair accessories. Product makes up 60% of the products circularity score, and packaging makes up 40% because the product is classified as Non-Durable."
        },
        consumable: {
            title: 'Consumable',
            description: `We define 'Consumable' products as products that are used up entirely in less than a year, assuming a normal or average rate of physical usage. E.g. cosmetics, food, cleaning products. ${
                result.packaging_circularity
                    ? 'Product makes up 30% of the products circularity score, and packaging makes up 70% because the product is classified as Consumable.'
                    : 'Product makes up 100% of the circularity score for this product due to the absence of packaging.'
            }`
        }
    };

    return mapping[result.durability?.toLowerCase() as keyof typeof mapping] ?? null;
};

const toMaterialsList = (materials?: Array<string>) => {
    if (!materials) return undefined;
    const deduped = [...new Set(materials)];
    if (deduped.length === 1) return `(${deduped[0].charAt(0).toUpperCase() + deduped[0].slice(1)})`;
    const list = `${deduped.slice(0, -1).join(', ')} and ${deduped.slice(-1)}`.toLowerCase();
    return `(${list.charAt(0).toUpperCase() + list.slice(1)})`;
};

const getMaterialMapping = (data: any[] | undefined, mapping: any, characteristicType: string) => {
    if (!data)
        return {
            product: [],
            packaging: []
        };
    const validResults = data
        .map((current) => {
            if (characteristicType === 'end_of_life') return formatEol(current);
            return current;
        })
        .filter(
            (current) =>
                current.characteristic_type === characteristicType &&
                mapping[current.characteristic] &&
                current.percentage !== null &&
                current.percentage > 0
        )
        .map((current) => ({
            ...current,
            value: current.percentage!,
            description: toMaterialsList(current.materials),
            ...mapping[current.characteristic]
        }))
        .sort(sortByKey((row) => row.value))
        .reverse();
    return {
        product: validResults.filter((current) => current.material_category === 'product'),
        packaging: validResults.filter((current) => current.material_category === 'packaging')
    };
};

export const CircularityCards = (props: PaperProps) => {
    const recycledOrReusedMaterialsMapping = useRecycledOrReusedMaterialsMapping();
    const biobasedMaterialsMapping = useBiobasedMaterialsMapping();
    const eolMapping = useEolMapping();
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { isEnabled } = useFeatureEnabled();

    const { dqr } = useScoreReport(id);
    const circularityDqr = dqr?.data?.results?.[0]?.circularity_dqr;
    const { circularity, summary } = useScoreReport(id);
    const { materialsResult, productResult } = circularity;

    const circularitySummary = summary?.find((current) => current.dimension === Dimension.Circularity);

    const firstProductResult = productResult.data?.results[0];

    const classification = getProductClassification(firstProductResult);
    const endOfLifeMaterials = useMemo(
        () => getMaterialMapping(materialsResult.data?.results, eolMapping, 'end_of_life'),
        [materialsResult.data?.results, eolMapping]
    );
    const circularityOfInputMaterials = useMemo(
        () => getMaterialMapping(materialsResult.data?.results, recycledOrReusedMaterialsMapping, 'circularity'),
        [materialsResult.data?.results, recycledOrReusedMaterialsMapping]
    );
    const biobasedMaterials = useMemo(
        () => getMaterialMapping(materialsResult.data?.results, biobasedMaterialsMapping, 'biobased'),
        [materialsResult.data?.results, biobasedMaterialsMapping]
    );

    return (
        <>
            <Section {...props}>
                <Group align="flex-start" justify="space-between" gap="xs">
                    <Stack gap={rem(4)} className={classes.introduction}>
                        <Title size="h2">{t('circularity')}</Title>
                        <Text c="dimmed" size="sm">
                            {t('circularityScoreReportDescription')}
                        </Text>
                    </Stack>

                    {circularitySummary && (
                        <ScoreCircleGraph
                            peerPercentage={circularitySummary.category_average ?? 0}
                            percentage={circularitySummary.score ?? 0}
                            size="md"
                            className={classes.scoreCircle}
                            bottom={
                                <Text size="sm" fw={500}>
                                    Category average: {circularitySummary.category_average ?? 0}
                                </Text>
                            }
                        />
                    )}
                </Group>
            </Section>

            <Section mt="md" {...props}>
                <Title size="h4" mb={rem(4)}>
                    Classification:{' '}
                    <Text component="span" fw={800} c="primary">
                        {classification?.title}
                    </Text>
                </Title>
                {classification?.description && (
                    <Text c="dimmed" size="sm">
                        {classification.description}
                    </Text>
                )}

                {productResult.isLoading ? (
                    <Skeleton mt="lg" height={200} data-testid="loading-classification" />
                ) : productResult.error ? (
                    <ErrorAlert error={productResult.error} />
                ) : (
                    <Group style={{ justifyContent: 'space-evenly' }} gap="xl" my={rem(32)}>
                        <ScoreCircleGraph
                            peerPercentage={firstProductResult?.product_circularity_average ?? 0}
                            percentage={firstProductResult?.product_circularity ?? 0}
                            size="xxl"
                            bottom={<Text fw={500}>Circularity of product</Text>}
                        >
                            <Text size="display" fw={700}>
                                {Math.round(firstProductResult?.product_circularity ?? 0)}
                            </Text>
                            <Text size="sm" fw={500}>
                                Product
                            </Text>
                            <Text size="sm" c="dimmed">
                                Category average: {firstProductResult?.product_circularity_average ?? 0}
                            </Text>
                        </ScoreCircleGraph>

                        <ScoreCircleGraph
                            peerPercentage={firstProductResult?.packaging_circularity_average ?? 0}
                            percentage={firstProductResult?.packaging_circularity ?? 0}
                            size="xxl"
                            bottom={<Text fw={500}>Circularity of packaging</Text>}
                        >
                            <Text size="display" fw={700}>
                                {Math.round(firstProductResult?.packaging_circularity ?? 0)}
                            </Text>
                            <Text size="sm" fw={500}>
                                Packaging
                            </Text>
                            <Text size="sm" c="dimmed">
                                Category average: {firstProductResult?.packaging_circularity_average ?? 0}
                            </Text>
                        </ScoreCircleGraph>
                    </Group>
                )}
            </Section>

            <Section mt="md" {...props}>
                <Title size="h4" mb={rem(4)}>
                    {t('circularityComparison')}
                </Title>
                <Text c="dimmed" size="sm" mb="xl">
                    {t('circularityComparisonDescription')}
                </Text>
                {productResult.isLoading ? (
                    <Skeleton my="lg" height={180} data-testid="loading-comparison" />
                ) : (
                    <ScoreReportBarChart
                        data={[
                            {
                                name: 'Product',
                                value: firstProductResult?.total_product_circularity ?? 0
                            },
                            {
                                name: 'Category average',
                                value: firstProductResult?.category_average ?? 0
                            }
                        ]}
                    />
                )}
                <ScoreColorLegend />
            </Section>

            <Section mt="md" {...props}>
                <Title size="h4" mb={rem(4)}>
                    {t('circularityOfInputMaterials')}
                </Title>

                <Text c="dimmed" size="sm">
                    {t('circularityOfInputMaterialsDescription')}
                </Text>

                {materialsResult.isLoading ? (
                    <Skeleton my="lg" height={180} />
                ) : (
                    <>
                        {circularityOfInputMaterials.product.length > 0 && (
                            <Group
                                mt={rem(40)}
                                mb={rem(60)}
                                style={{
                                    justifyContent: 'space-evenly'
                                }}
                                gap="xl"
                            >
                                <WaffleGraph
                                    title="Circularity of product input materials"
                                    data={circularityOfInputMaterials.product}
                                    m={0}
                                />
                                <GraphLegend
                                    items={circularityOfInputMaterials.product.map((row) => ({
                                        ...row,
                                        value: toPercentage(row.value)
                                    }))}
                                />
                            </Group>
                        )}

                        {circularityOfInputMaterials.packaging.length > 0 && (
                            <Group
                                my={rem(40)}
                                style={{
                                    justifyContent: 'space-evenly'
                                }}
                                gap="xl"
                            >
                                <WaffleGraph
                                    title="Circularity of packaging input materials"
                                    data={circularityOfInputMaterials.packaging}
                                    m={0}
                                />
                                <GraphLegend
                                    items={circularityOfInputMaterials.packaging.map((row) => ({
                                        ...row,
                                        value: toPercentage(row.value)
                                    }))}
                                />
                            </Group>
                        )}
                    </>
                )}
            </Section>

            <Section mt="md" {...props}>
                <Title size="h4" mb={rem(4)}>
                    {t('sourceOfInputMaterials')}
                </Title>

                <Text c="dimmed" size="sm">
                    {t('sourceOfInputMaterialsDescription')}
                </Text>

                {materialsResult.isLoading ? (
                    <Skeleton my="lg" height={180} />
                ) : materialsResult.error ? (
                    <ErrorAlert error={materialsResult.error} />
                ) : (
                    <>
                        {biobasedMaterials.product.length > 0 && (
                            <Group
                                mt={rem(40)}
                                mb={rem(60)}
                                style={{
                                    justifyContent: 'space-evenly'
                                }}
                                gap="xl"
                            >
                                <WaffleGraph title="Source of product input materials" data={biobasedMaterials.product} m={0} />
                                <GraphLegend
                                    items={biobasedMaterials.product.map((row) => ({
                                        ...row,
                                        value: toPercentage(row.value)
                                    }))}
                                />
                            </Group>
                        )}

                        {biobasedMaterials.packaging.length > 0 && (
                            <Group
                                my={rem(40)}
                                style={{
                                    justifyContent: 'space-evenly'
                                }}
                                gap="xl"
                            >
                                <WaffleGraph title="Source of packaging input materials" data={biobasedMaterials.packaging} m={0} />
                                <GraphLegend
                                    items={biobasedMaterials.packaging.map((row) => ({
                                        ...row,
                                        value: toPercentage(row.value)
                                    }))}
                                />
                            </Group>
                        )}
                    </>
                )}
            </Section>

            {firstProductResult?.durability && ['DURABLE', 'SEMI-DURABLE'].includes(firstProductResult.durability) && (
                <Section mt="md" {...props}>
                    <Title size="h4" mb={rem(4)}>
                        {t('lifetimeExtension')}
                    </Title>
                    <Text c="dimmed" size="sm" mb={rem(32)}>
                        {t('lifetimeExtensionDescription')}
                    </Text>

                    <Group align="center" justify="center">
                        <ScoreCircleGraph percentage={firstProductResult.product_lifetime_extension ?? 0} size="sm" />
                    </Group>
                </Section>
            )}

            <Section mt="md" {...props}>
                <Title size="h4" mb={rem(4)}>
                    {t('endOfLifeMaterials')}
                </Title>
                <Text c="dimmed" size="sm">
                    {t('endOfLifeMaterialsDescription')}
                </Text>

                {materialsResult.isLoading ? (
                    <Skeleton my="lg" height={180} />
                ) : materialsResult.error ? (
                    <ErrorAlert error={materialsResult.error} />
                ) : (
                    <>
                        {endOfLifeMaterials?.product.length > 0 && (
                            <Group
                                my={rem(40)}
                                style={{
                                    justifyContent: 'space-evenly'
                                }}
                                gap="xl"
                            >
                                <WaffleGraph title="Product" data={endOfLifeMaterials.product} m={0} />
                                <GraphLegend
                                    items={endOfLifeMaterials.product.map((row) => ({
                                        ...row,
                                        value: toPercentage(row.value)
                                    }))}
                                />
                            </Group>
                        )}

                        {endOfLifeMaterials?.packaging.length > 0 && (
                            <Group
                                my={rem(40)}
                                style={{
                                    justifyContent: 'space-evenly'
                                }}
                                gap="xl"
                            >
                                <WaffleGraph title="Packaging" data={endOfLifeMaterials.packaging} m={0} />
                                <GraphLegend
                                    items={endOfLifeMaterials.packaging.map((row) => ({
                                        ...row,
                                        value: toPercentage(row.value)
                                    }))}
                                />
                            </Group>
                        )}
                    </>
                )}
            </Section>
            {isEnabled(FlagName.DataQualityRating) && (
                <Section mt="md" {...props}>
                    <DataQualityCard score={circularityDqr ?? undefined} />
                </Section>
            )}
            <Text c="dimmed" fs="italic" size="sm" my="lg">
                {t('circularityScoreReportFooter')}
            </Text>
        </>
    );
};
