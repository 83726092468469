import { useGroup } from 'account-management/groups/use-group';
import { useOrganizationGroups } from 'account-management/groups/use-organization-groups';
import { useOrganizationUsers } from 'account-management/users/use-organization-users';
import { paths } from 'paths';
import { Section } from 'shared/section';
import { StaticTable } from 'shared/static-table';
import { useFeatureEnabled } from 'shared/use-feature-enabled';
import { DataLevelPermission, Permission } from 'types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbDotsVertical } from 'react-icons/tb';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    ActionIcon,
    Anchor,
    Breadcrumbs,
    Button,
    Code,
    Group,
    Menu,
    MultiSelect,
    rem,
    Skeleton,
    Stack,
    Text,
    Textarea,
    TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { FlagName } from 'utils/get-flag-value';
import { showErrorMessage } from 'utils/show-error-message';
import { showSuccessMessage } from 'utils/show-success-message';
import { validation } from 'utils/validation';
import { DataLevelPermissionModal } from './data-level-permission-modal';
import { PermissionModal } from './permission-modal';
import { RESERVED_GROUPS } from './reserved-groups';

export const GroupDetail = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isEnabled } = useFeatureEnabled();
    const [showPermissionModal, setShowPermissionModal] = useState(false);
    const [showDataLevelPermissionModal, setShowDataLevelPermissionModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { organizationId, id } = useParams<{ organizationId: string; id: string }>();
    const isCreate = id === 'create';

    const { mutate } = useOrganizationGroups();
    const { data: organizationUsers } = useOrganizationUsers();
    const { data, create, update, updateGroup, isLoading } = useGroup(!isCreate ? id : undefined);

    const form = useForm({
        initialValues: {
            name: '',
            description: '',
            user_ids: [] as Array<string>,
            permissions: [] as Permission[],
            data_level_permissions: [] as DataLevelPermission[]
        },

        validate: {
            name: validation.required
        }
    });

    useEffect(() => {
        if (data && !isCreate) {
            const values = {
                name: data.name ?? '',
                description: data.description ?? '',
                user_ids: data.users.map((user) => user.id) ?? [],
                permissions: data.permissions ?? [],
                data_level_permissions: data.data_level_permissions ?? []
            };
            form.setValues(values);
            form.resetDirty(values);
        }
    }, [data, isCreate]);

    const handleSubmit = form.onSubmit(async (values) => {
        try {
            setIsSubmitting(true);
            if (isCreate) {
                const { user_ids, ...createValues } = values;
                const group = await create(createValues);

                // Workaround: Group create does not add users hence the subsequent call to update
                if (group && group.id) {
                    if (user_ids.length > 0) {
                        await updateGroup(group.id, {
                            user_ids_add: values.user_ids
                        });
                    }
                }
                navigate(paths.accountManagement.groups(organizationId));
            } else {
                await update({
                    name: values.name,
                    description: values.description,
                    user_ids_add: values.user_ids.filter((current) => !data?.users.map((user) => user.id)?.includes(current)),
                    user_ids_remove: data?.users.map((user) => user.id)?.filter((current) => !values.user_ids.includes(current)),
                    permissions_add: values.permissions.filter(
                        (current) => !data?.permissions?.find((permission) => permission.name === current.name)
                    ),
                    permissions_remove: data?.permissions
                        .filter((current) => !values?.permissions?.find((permission) => permission.name === current.name))
                        .map((current) => current.name),
                    data_level_permissions_add: values.data_level_permissions.filter(
                        (current) => !data?.data_level_permissions?.find((dataLevelPermission) => dataLevelPermission.name === current.name)
                    ),
                    data_level_permissions_remove:
                        data?.data_level_permissions
                            ?.map((current) => current.name)
                            ?.filter((name) => {
                                const stillExists = values?.data_level_permissions?.find((permission) => permission.name === name);
                                return !stillExists;
                            }) ?? []
                });
            }
            mutate();
            showSuccessMessage(t('groupSaved'));
        } catch (error) {
            showErrorMessage(error);
        } finally {
            setIsSubmitting(false);
        }
    });

    const users = useMemo(() => {
        if (!organizationUsers) return [];
        return organizationUsers.items.map((current) => ({
            label: current.email,
            value: current.id
        }));
    }, [organizationUsers]);

    const isDataLevelPermissionsEnabled = isEnabled(FlagName.DataLevelPermissions);

    return (
        <>
            <Breadcrumbs mb={rem(16)}>
                <Anchor component={Link} to={`/organization/${organizationId}/groups`}>
                    {t('groups')}
                </Anchor>
                <Text>{isCreate ? t('create') : data?.name ?? '-'}</Text>
            </Breadcrumbs>

            <Section>
                {!isCreate && isLoading ? (
                    <Skeleton height={300} />
                ) : (
                    <form onSubmit={handleSubmit}>
                        <Stack gap="xs">
                            <TextInput
                                disabled={!isCreate && data && RESERVED_GROUPS.includes(data.name)}
                                withAsterisk
                                label={t('name')}
                                {...form.getInputProps('name')}
                                maw={400}
                            />
                            <Textarea label={t('description')} autosize minRows={3} {...form.getInputProps('description')} />
                            <MultiSelect
                                searchable
                                data={users}
                                label={t('users')}
                                {...form.getInputProps('user_ids')}
                                data-testid="group-users"
                            />
                            <Stack mt={rem(20)} gap="md">
                                <Group justify="space-between">
                                    <Text fw={500}>{t('permissions')}</Text>
                                    <Button variant="outline" onClick={() => setShowPermissionModal(true)}>
                                        {t('addPermission')}
                                    </Button>
                                </Group>
                                <StaticTable
                                    searchable={false}
                                    data={form.values.permissions}
                                    rowKey={(row) => row.resource}
                                    columns={[
                                        {
                                            key: 'name',
                                            name: t('name'),
                                            sortable: true
                                        },
                                        {
                                            key: 'description',
                                            name: t('description'),
                                            render: (value) => value ?? '-',
                                            sortable: true
                                        },
                                        {
                                            key: 'resource',
                                            name: t('resource'),
                                            render: (value) => <Code>{value}</Code>
                                        },
                                        {
                                            key: 'role',
                                            name: t('role'),
                                            render: (value) => {
                                                if (value === 'Owner') {
                                                    return t('ownerRoleDescription');
                                                }

                                                if (value === 'Editor') {
                                                    return t('editorRoleDescription');
                                                }

                                                return t('viewerRoleDescription');
                                            }
                                        },
                                        {
                                            key: 'actions',
                                            align: 'right',
                                            name: '',
                                            render: (_, row) => {
                                                return (
                                                    <Menu shadow="md">
                                                        <Menu.Target>
                                                            <ActionIcon data-testid={`menu-${row.key}`}>
                                                                <TbDotsVertical size={16} />
                                                            </ActionIcon>
                                                        </Menu.Target>
                                                        <Menu.Dropdown>
                                                            <Menu.Item
                                                                disabled={row.resource === '/'}
                                                                color="red"
                                                                onClick={() => {
                                                                    form.setFieldValue(
                                                                        'permissions',
                                                                        form.values.permissions.filter(
                                                                            (current) => current.name !== row.name
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                {t('remove')}
                                                            </Menu.Item>
                                                        </Menu.Dropdown>
                                                    </Menu>
                                                );
                                            }
                                        }
                                    ]}
                                />
                            </Stack>

                            {isDataLevelPermissionsEnabled && (
                                <Stack mt={rem(20)} gap="md">
                                    <Group justify="space-between">
                                        <Text fw={500}>{t('dataLevelPermissions')}</Text>
                                        <Button variant="outline" onClick={() => setShowDataLevelPermissionModal(true)}>
                                            {t('addDataLevelPermission')}
                                        </Button>
                                    </Group>
                                    <StaticTable
                                        searchable={false}
                                        data={form.values.data_level_permissions}
                                        columns={[
                                            {
                                                key: 'name',
                                                name: t('name'),
                                                sortable: true
                                            },
                                            {
                                                key: 'resource',
                                                name: t('resource'),
                                                render: (value) => <Code>{value}</Code>
                                            },
                                            {
                                                key: 'column',
                                                name: t('column'),
                                                render: (value) => <Code>{value}</Code>
                                            },
                                            {
                                                key: 'operator',
                                                name: t('operator'),
                                                render: (value) => <Code>{value}</Code>
                                            },
                                            {
                                                key: 'value',
                                                name: t('value'),
                                                render: (value) => <Code>{value}</Code>
                                            },

                                            {
                                                key: 'actions',
                                                align: 'right',
                                                name: '',
                                                render: (_, row) => {
                                                    return (
                                                        <Menu shadow="md">
                                                            <Menu.Target>
                                                                <ActionIcon data-testid={`menu-${row.id}`}>
                                                                    <TbDotsVertical size={16} />
                                                                </ActionIcon>
                                                            </Menu.Target>
                                                            <Menu.Dropdown>
                                                                <Menu.Item
                                                                    color="red"
                                                                    onClick={() => {
                                                                        form.setFieldValue(
                                                                            'data_level_permissions',
                                                                            form.values.data_level_permissions.filter(
                                                                                (current) => current.name !== row.name
                                                                            )
                                                                        );
                                                                    }}
                                                                >
                                                                    {t('remove')}
                                                                </Menu.Item>
                                                            </Menu.Dropdown>
                                                        </Menu>
                                                    );
                                                }
                                            }
                                        ]}
                                    />
                                </Stack>
                            )}
                        </Stack>
                        <Group justify="flex-end" mt="xl">
                            <Button loading={isSubmitting} type="submit">
                                {isCreate ? t('createGroup') : t('updateGroup')}
                            </Button>
                        </Group>
                    </form>
                )}
            </Section>
            <PermissionModal
                currentPermissions={form.values.permissions}
                isOpen={showPermissionModal}
                onClose={() => setShowPermissionModal(false)}
                onSubmit={(newPermission) => {
                    form.setFieldValue('permissions', [...form.values.permissions, newPermission]);
                }}
            />
            <DataLevelPermissionModal
                currentPermissions={form.values.data_level_permissions}
                isOpen={showDataLevelPermissionModal}
                onClose={() => setShowDataLevelPermissionModal(false)}
                onSubmit={(newPermission) => {
                    form.setFieldValue('data_level_permissions', [...form.values.data_level_permissions, newPermission]);
                }}
            />
        </>
    );
};
