import { fetchAndActivate } from 'firebase/remote-config';
import { useCallback, useEffect, useState } from 'react';
import { remoteConfig } from 'lib/firebase';
import { FlagName, FlagValue, getFlagValue } from 'utils/get-flag-value';
import { report } from 'utils/report-error';
import { useOrganizationId } from './use-organization-id';
import { useUser } from './use-user';

export const useFeatureEnabled = () => {
    const [isInitialized, setIsInitialized] = useState(false);
    const { isDayrize, isDayrizeImpersonating } = useUser();
    const organizationId = useOrganizationId();

    const parseRemoteConfigValue = (value: string) => {
        try {
            const parsedValue = JSON.parse(value);
            return parsedValue;
        } catch (error) {
            return value;
        }
    };

    const isEnabled = useCallback(
        (flagName: FlagName, exactValue?: 'off' | 'internal' | 'preview' | 'on') => {
            if (!isInitialized) return false;
            const rawValue = getFlagValue(flagName);
            const value = parseRemoteConfigValue(rawValue);

            if (typeof value === 'object' && 'orgs' in value && Array.isArray(value.orgs)) {
                if (value.orgs.includes(organizationId)) {
                    return true;
                }
            }

            if (typeof value === 'string') {
                if (exactValue !== undefined) {
                    return value === exactValue;
                }
                if (value === FlagValue.On || value === FlagValue.Preview) return true;
                if (value === FlagValue.Internal && isDayrize) return true;
                if (value === FlagValue.InternalWithImpersonation && (isDayrize || isDayrizeImpersonating)) return true;
            }
            return false;
        },
        [isDayrize, isInitialized]
    );

    useEffect(() => {
        fetchAndActivate(remoteConfig)
            .then(() => {
                setIsInitialized(true);
            })
            .catch(report);
    }, []);

    return {
        isEnabled,
        isInitialized
    };
};
