import { createInsightData } from 'insights-explorer/create-insight-data';
import { InsightCard } from 'insights-explorer/insight-card';
import useSWR from 'swr';
import { ActionableInsightWidget, QueryReturnResult, ValueWidget as ValueWidgetType } from 'types';
import { Grid } from '@mantine/core';
import { RunQueryConfig, runQuery } from 'utils/run-query';
import { useWidgetQuery } from './use-widget-query';

export const InsightWidget = ({ config, range }: { config: ValueWidgetType; range: string }) => {
    const query = useWidgetQuery(config, range);

    const result = useSWR<QueryReturnResult<any>>(config ? [config.query_name, query] : null, async ([_, query]) =>
        runQuery(query as RunQueryConfig)
    );

    const firstDataItem = result?.data?.results?.[0]
        ? {
              widgets: [createInsightData(result.data.results[0])]
          }
        : null;
    return (
        <section>
            <Grid align="stretch" mb="md" gutter="md">
                {firstDataItem?.widgets.map((item: ActionableInsightWidget) => (
                    <Grid.Col key={item.name} span={{ md: 6, sm: 12 }}>
                        <InsightCard
                            name={item.name}
                            newName={item.newName}
                            data={item.insightData}
                            link={config?.link ?? ''}
                            title={config?.name}
                        />
                    </Grid.Col>
                ))}
            </Grid>
        </section>
    );
};
