import { useResources } from 'account-management/groups/use-resources';
import { useOpenApiSpec } from 'shared/use-openapi-spec';
import { roleMap } from 'shared/use-user';
import { Permission, RoleType } from 'types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbCheck } from 'react-icons/tb';
import { Button, Group, Modal, Select, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { validation } from 'utils/validation';

export const PermissionModal = ({
    isOpen,
    onClose,
    onSubmit,
    currentPermissions
}: {
    currentPermissions: Permission[];
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (values: { name: string; description: string; resource: string; role: RoleType }) => void;
}) => {
    const { data: spec } = useOpenApiSpec();
    const { t } = useTranslation();
    const { data: resourceData, isLoading } = useResources();
    const [search, setSearch] = useState('');

    const form = useForm({
        initialValues: {
            name: '',
            description: '',
            resource: '',
            role: 'Viewer' as RoleType
        },
        validate: {
            name: validation.required,
            resource: validation.required
        }
    });

    const mappedPaths = useMemo(() => {
        if (!spec) return [];
        let paths: Array<{
            path: string;
            method: string;
            title: string;
            description: string;
        }> = [];

        Object.keys(spec.paths).forEach((path) => {
            const result = spec.paths[path];
            Object.keys(result).forEach((method) => {
                const current = result[method];
                paths.push({
                    path: path.replace('/api', '').replace('/{key}', ''),
                    method: method.toUpperCase(),
                    title: current.summary,
                    description: current.description
                });
            });
        });
        return paths;
    }, [spec]);

    const handleSubmit = form.onSubmit((values) => {
        const nameExists = !!currentPermissions.find((current) => current.name === values.name);
        if (nameExists) {
            notifications.show({ message: t('nameAlreadyExists'), color: 'red' });
            return;
        }
        onSubmit(values);
        onClose();
        form.reset();
    });

    const resourceOptions = useMemo(() => {
        const validMethods = roleMap[form.values.role];
        if (!resourceData || !validMethods) return [];
        const allResources = resourceData
            .filter((resource) => {
                const isValidMethod = validMethods.includes(resource.method);
                const isAlreadyAdded = !!currentPermissions.find(
                    (permission) => permission.role === form.values.role && permission.resource === resource.resource
                );
                return isValidMethod && !isAlreadyAdded;
            })
            .map((current) => {
                const match = mappedPaths.find((path) => path.path === current.resource && path.method === current.method);
                return {
                    label: match?.title ?? current.resource,
                    value: current.resource,
                    description: match?.description ?? ''
                };
            });

        // Deduplicate the list as resources have the same value (api endpoint) for GET/PATCH operations which causes a 500 error on the select list
        const deduplicatedData = allResources.filter((item, index, self) => index === self.findIndex((t) => t.value === item.value));

        return deduplicatedData;
    }, [form.values.role, currentPermissions, resourceData, mappedPaths]);

    return (
        <Modal size="md" opened={isOpen} onClose={onClose} title={t('addPermission')} centered>
            <form onSubmit={handleSubmit}>
                <Stack gap="xs">
                    <TextInput {...form.getInputProps('name')} withAsterisk label={t('name')} />
                    <TextInput {...form.getInputProps('description')} label={t('description')} />
                    <Select
                        comboboxProps={{ position: 'bottom', withinPortal: true }}
                        label={t('role')}
                        description={t('roleDescription')}
                        withAsterisk
                        allowDeselect={false}
                        data={[
                            {
                                value: 'Owner',
                                label: t('ownerRoleDescription')
                            },
                            {
                                value: 'Editor',
                                label: t('editorRoleDescription')
                            },
                            {
                                value: 'Viewer',
                                label: t('viewerRoleDescription')
                            }
                        ]}
                        value={form.values.role}
                        onChange={(value) => {
                            form.setFieldValue('role', value as RoleType);
                            form.setFieldValue('resource', '');
                        }}
                    />
                    <Select
                        label={t('resource')}
                        withAsterisk
                        comboboxProps={{ position: 'bottom', withinPortal: true }}
                        disabled={isLoading}
                        data={resourceOptions}
                        allowDeselect={false}
                        {...form.getInputProps('resource')}
                        onSearchChange={setSearch}
                        searchValue={search}
                        searchable
                        nothingFoundMessage={t('noMatchingResource')}
                        renderOption={({ option, checked }: any) => {
                            return (
                                <Group w="100%" py="xs" align="center" justify="space-between">
                                    <Stack gap="xs" maw="80%">
                                        <Text size="sm">{option.label}</Text>
                                        {option.description && (
                                            <Text size="xs" c="dimmed">
                                                {option.description}
                                            </Text>
                                        )}
                                    </Stack>
                                    {checked && <TbCheck color="#0b4abf" size={16} />}
                                </Group>
                            );
                        }}
                    />
                </Stack>
                <Group mt="lg" justify="flex-end">
                    <Button type="submit">{t('add')}</Button>
                </Group>
            </form>
        </Modal>
    );
};
