import useSWR from 'swr';
import { QueryResult, RuleConfig } from 'types';
import { request } from 'lib/request-client';

export const useRuleConfigs = () => {
    const { data, error, mutate, isLoading } = useSWR<QueryResult<RuleConfig>>('/rule_manager/rule_configs/query');

    const remove = async (id: string) => {
        await request.url(`/rule_manager/rule_config/${id}`).delete();
        mutate();
    };

    return {
        data,
        error,
        mutate,
        isLoading,
        remove
    };
};
