import { TableFilterVariant, useTableFilters } from 'shared/use-table-filters';
import useSWR from 'swr';
import { Filter, Nullable, QueryReturnResult } from 'types';
import { useEffect } from 'react';
import { runQuery } from 'utils/run-query';

export interface ProductWithScoringResults {
    id: string;
    sku: string;
    name: string;
    brand: Nullable<string>;
    category_id: Nullable<string>;
    category: Nullable<string>;
    tags: Array<string>;
    created_at: string;
    updated_at: string;
    scored_at: Nullable<string>;
    total_score: Nullable<number>;
    circularity_score: Nullable<number>;
    ecosystem_score: Nullable<number>;
    climate_score: Nullable<number>;
    livelihood_and_wellbeing_score: Nullable<number>;
    purpose_score: Nullable<number>;
}

const QUERY_NAME = 'product_scoring_results';

export const useProducts = (
    params?: Filter[],
    config?: {
        variant?: TableFilterVariant;
        initialOrdering?: string;
        isEnabled?: boolean;
    }
) => {
    const {
        onPageTokenMappingChange,
        page,
        onPage,
        onLimitChange,
        onOrderChange,
        limit,
        order,
        query,
        search,
        onSearchChange,
        onOrderDirectionChange,
        orderDirection
    } = useTableFilters({
        searchKey: 'name',
        params,
        variant: config?.variant ?? 'query',
        initialOrdering: config?.initialOrdering
    });

    const requestQuery = {
        name: QUERY_NAME,
        pageSize: query.page_size,
        pageToken: query.page_token,
        params: query.params,
        orderByParams: query.orderByParams
    };

    const shouldMakeRequest = config?.isEnabled === undefined || config.isEnabled;
    const { data, error, mutate, isLoading, isValidating } = useSWR<QueryReturnResult<ProductWithScoringResults>>(
        shouldMakeRequest ? [QUERY_NAME, requestQuery] : null,
        async ([_, query]) => runQuery(query as any)
    );

    useEffect(() => {
        if (data && !isLoading && data.next_page_token) {
            onPageTokenMappingChange((current) => ({
                ...current,
                [page + 1]: data.next_page_token
            }));
        }
    }, [data, isLoading, onPageTokenMappingChange, page]);

    return {
        data,
        error,
        mutate,
        isLoading,
        onPageTokenMappingChange,
        page,
        onPage,
        onLimitChange,
        onOrderChange,
        orderDirection,
        onOrderDirectionChange,
        search,
        onSearchChange,
        limit,
        order,
        isValidating
    };
};
