import { ErrorAlert } from 'shared/error-alert';
import { useUser } from 'shared/use-user';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TbAlertCircle } from 'react-icons/tb';
import { Alert, Button, Group, Loader, Modal, Stack, Text } from '@mantine/core';
import { useNamespaceUsers } from './use-namespace-users';

export const ImpersonateModal = ({ namespace, isOpen, onClose }: { namespace: string | null; isOpen: boolean; onClose: () => void }) => {
    const { t } = useTranslation();
    const { activeUser } = useUser();
    const { impersonations, isLoading, impersonationsError, usersError, users } = useNamespaceUsers(namespace);

    const user = useMemo(() => {
        if (!activeUser || !impersonations || !users) return null;
        const impersonation = impersonations.items.find((impersonation) => impersonation.user_id.includes(activeUser.id));
        const user = users.find((user) => user.id === impersonation?.impersonated_user_id);
        return user;
    }, [activeUser, impersonations, users]);

    if (!namespace) return null;
    const error = usersError || (impersonationsError?.status != 404 ? impersonationsError : undefined);

    return (
        <Modal size="md" title={t('impersonate')} opened={isOpen} onClose={onClose}>
            {error ? (
                <ErrorAlert error={error} />
            ) : isLoading ? (
                <Stack p="xl" align="center" justify="center">
                    <Loader data-testid="loading" size="xl" />
                </Stack>
            ) : user ? (
                <>
                    <Text c="dimmed">
                        Do you want to impersonate{' '}
                        <strong>
                            {user.name} ({user.email})
                        </strong>{' '}
                        in the <strong>{namespace}</strong> namespace?
                    </Text>
                    <Group mt="lg" justify="flex-end">
                        <Button
                            onClick={() => {
                                const url = `${window.location.origin}?as=${namespace}`;
                                window.location.href = url;
                            }}
                            loading={isLoading}
                        >
                            {isLoading ? t('loading') : t('impersonate')}
                        </Button>
                    </Group>
                </>
            ) : (
                <Alert icon={<TbAlertCircle size={20} />} title="No impersonation exists" color="red">
                    You do not have an existing impersonation in the <strong>{namespace}</strong> namespace.
                </Alert>
            )}
        </Modal>
    );
};
