export const getDateColumns = (type = 'at', sortable = false) => [
    {
        key: `created_${type}`,
        name: 'Created on',
        sortable: true,
        render: (value: string) => new Date(value).toLocaleString()
    },
    {
        key: `updated_${type}`,
        name: 'Updated on',
        sortable: true,
        render: (value: string) => new Date(value).toLocaleString()
    }
];
