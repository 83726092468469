import LookerDashboard from 'shared/looker-dashboard';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Group, Tabs, Text, Title } from '@mantine/core';
import classes from './tabs.module.css';

interface DashboardTabsProps {
    title: string;
    description: string;
    tabOptions: Array<{ name: string; dashboardId: number }>;
}

export const LookerDashboardTabs = ({ title, description, tabOptions }: DashboardTabsProps) => {
    const { id } = useParams<{ id: string }>();
    const initialTab = tabOptions.find((option) => option.name === id)?.name ?? tabOptions[0].name;
    const [activeTab, setActiveTab] = useState(initialTab);

    useEffect(() => {
        const matchedTab = tabOptions.find((option) => option.name === id)?.name;
        if (matchedTab) {
            setActiveTab(matchedTab);
        }
    }, [id, tabOptions]);

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Group justify="space-between" align="center" mb="md" style={{ margin: '10px' }}>
                <Box>
                    <Title size="h3">{title}</Title>
                    <Text c="dimmed" size="sm">
                        {description}
                    </Text>
                </Box>
            </Group>
            <Tabs
                variant="outline"
                value={activeTab}
                onChange={(value) => value && setActiveTab(value)}
                classNames={{ tab: classes.tab, panel: classes.panel }}
                style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
            >
                <Tabs.List>
                    {tabOptions.map((tab) => (
                        <Tabs.Tab key={tab.dashboardId} value={tab.name}>
                            {tab.name}
                        </Tabs.Tab>
                    ))}
                </Tabs.List>
                {tabOptions.map((tab) => (
                    <Tabs.Panel key={tab.dashboardId} value={tab.name} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <LookerDashboard dashboardId={tab.dashboardId} style={{ flexGrow: 1 }} />
                    </Tabs.Panel>
                ))}
            </Tabs>
        </Box>
    );
};
