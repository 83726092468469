import { GLOBAL_COUNTRY_CODES } from 'data-management/data-ingestion/product-upload/get-alpha3-country-code-name';
import useSWR from 'swr';
import { Filter } from 'types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbCheck } from 'react-icons/tb';
import { Badge, Group, Loader, Select, SelectProps, Stack, Text, rem } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { RunQueryConfig, runQuery } from 'utils/run-query';

type Tag = 'organic' | 'region' | 'unit' | 'recycled' | 'reused';

export type Match = {
    isChecked?: boolean;
    label: string;
    value: string;
    region?: string;
    unit?: string;
    organic?: boolean;
    recycled?: boolean;
    reused?: boolean;
    tags?: Array<string>;
};

const renderSelectOption: SelectProps['renderOption'] = ({ option }) => {
    const { region, label, unit, organic, recycled, isChecked, reused, tags } = option as any;

    return (
        <Stack flex="1" gap={rem(4)} py="xs">
            <Group gap="xs">
                <Text size="sm" fw={500}>
                    {label}
                </Text>
                {isChecked && <TbCheck size={16} />}
            </Group>
            <Group gap="xs">
                {region && tags.includes('region') && (
                    <Badge size="xs">{GLOBAL_COUNTRY_CODES.includes(region.toUpperCase()) ? 'Unknown' : region}</Badge>
                )}
                {unit && tags.includes('unit') && <Badge size="xs">{unit}</Badge>}
                {organic && tags.includes('organic') && <Badge size="xs">Organic</Badge>}
                {reused && tags.includes('reused') && <Badge size="xs">Reused</Badge>}
                {recycled && tags.includes('recycled') && <Badge size="xs">Recycled</Badge>}
            </Group>
        </Stack>
    );
};

const DEFAULT_TAGS: Tag[] = ['organic', 'region', 'recycled', 'reused'];

export const MaterialsAutoComplete = ({
    initialOption,
    additionalFilters = [],
    initialOptions = [],
    tags = DEFAULT_TAGS,
    onChange,
    onClear,
    showInitialOption = true,
    ...rest
}: {
    initialOption: { label: string; value: string };
    additionalFilters?: Filter[];
    initialOptions?: Match[];
    onClear?: () => void;
    tags?: Tag[];
    showInitialOption?: boolean;
} & SelectProps) => {
    const [isSearching, setSearching] = useState(false);
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue] = useDebouncedValue(searchValue, 500);

    const query: RunQueryConfig = {
        name: 'materials',
        pageSize: 50,
        params: [
            ...additionalFilters,
            {
                name: 'beautified_name',
                operation: '~',
                value: debouncedSearchValue
            }
        ]
    };

    const shouldSearch = debouncedSearchValue && debouncedSearchValue.length > 3;

    const {
        data: matchingMaterials,
        isLoading,
        isValidating
    } = useSWR(shouldSearch ? [query.name, debouncedSearchValue, query] : null, ([_, __, query]) => runQuery(query));

    const showSpinner = (shouldSearch && isLoading) || isValidating;
    const hasData = matchingMaterials && matchingMaterials?.results?.length > 0;

    const allowClear = !!onClear;

    const options = useMemo(() => {
        const hasValidInitialOption = !!initialOption?.value;
        if (!matchingMaterials) {
            return hasValidInitialOption ? [initialOption as Match, ...initialOptions] : initialOptions;
        }

        const formatted: Match[] = matchingMaterials.results.map((material) => ({
            isChecked: material.id === initialOption.value,
            label: material.beautified_name,
            value: material.id,
            region: material.region,
            unit: material.unit,
            organic: material.organic,
            recycled: material.recycled,
            reused: material.reused,
            tags
        }));

        const filtered = formatted.filter((current) => current.label && current.value);
        if (!showInitialOption && hasValidInitialOption) {
            return filtered;
        }
        return filtered.some((option) => option.value === initialOption.value) ? filtered : [initialOption, ...formatted];
    }, [matchingMaterials, initialOption, tags, showInitialOption, initialOptions]);

    return (
        <Select
            {...rest}
            searchable
            searchValue={searchValue}
            onSearchChange={(value) => {
                setSearchValue(value);
            }}
            placeholder={t('searchForMaterial')}
            allowDeselect={allowClear}
            renderOption={renderSelectOption}
            clearable={allowClear}
            value={!isSearching ? initialOption.value : undefined}
            onFocus={() => {
                setSearchValue('');
                setSearching(true);
            }}
            onBlur={() => {
                if (!searchValue && allowClear && onClear) {
                    onClear();
                }
                setSearching(false);
                setSearchValue('');
            }}
            onChange={(value) => {
                if (onChange) onChange(value, options.find((current) => current.value === value)!);
            }}
            nothingFoundMessage={showSpinner ? t('loadingMatches') : hasData ? t('noMatchingMaterialsFound') : t('searchForMaterial')}
            data={options}
            rightSection={showSpinner && <Loader size="xs" color="blue" />}
        />
    );
};
