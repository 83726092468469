import useSWR from 'swr';
import { RuleConfig, RuleConfigUpdate } from 'types';
import { request } from 'lib/request-client';

export const ruleConfigCategories = ['Default_JSONL', 'Proof_Files'];

export enum RuleType {
    TRANSFORM = 'transform',
    FORMAT = 'format',
    SPLIT = 'split',
    COMBINE = 'combine',
    REPLACE = 'replace',
    COMPUTE = 'compute',
    VALIDATE = 'validate',
    MAP = 'map'
}

export const ruleTypes = [
    {
        label: 'Transform',
        value: RuleType.TRANSFORM
    },
    {
        label: 'Format',
        value: RuleType.FORMAT
    },
    {
        label: 'Split',
        value: RuleType.SPLIT
    },
    {
        label: 'Combine',
        value: RuleType.COMBINE
    },
    {
        label: 'Replace',
        value: RuleType.REPLACE
    },
    {
        label: 'Compute',
        value: RuleType.COMPUTE
    },
    {
        label: 'Validate',
        value: RuleType.VALIDATE
    },
    {
        label: 'Map',
        value: RuleType.MAP
    }
];

export const ruleCodes = [
    {
        label: 'Assign transports data',
        value: 'AssignTransportsRule'
    },
    {
        label: 'Assign material ID based on its properties',
        value: 'AssignMaterialIdRule'
    },
    {
        label: "Assign each product's category ID",
        value: 'AssignCategoryRule'
    },
    {
        label: "Assign each product's energy consumption",
        value: 'AssignProductionEnergyRule'
    },
    {
        label: 'Convert weight',
        value: 'ConvertWeightToKgRule'
    },
    {
        label: 'Convert energy units to MJ',
        value: 'ConvertEnergyUnitToMJRule'
    },
    {
        label: 'Compute characteristics',
        value: 'ComputeMaterialCharacteristicRule'
    },
    {
        label: 'Split material based on recycled',
        value: 'SplitMaterialFromRecycledRule'
    }
];

export const useRuleConfig = (id?: string) => {
    const { data, error, mutate, isLoading } = useSWR<RuleConfig>(id ? `/rule_manager/rule_config/${id}` : null);

    const update = async (body: Partial<RuleConfigUpdate>) => {
        await request.url(`/rule_manager/rule_config/${id}`).patch(body);
        mutate();
    };

    const create = async (body: Partial<RuleConfig>) => {
        await request.url(`/rule_manager/rule_config/create`).post(body);
        mutate();
    };

    return { data, error, mutate, isLoading, update, create };
};
