import { useTableFilters } from 'shared/use-table-filters';
import useSWR from 'swr';
import { Filter, Nullable, QueryReturnResult } from 'types';
import { useEffect } from 'react';
import { runQuery } from 'utils/run-query';

export type AuditLogItem = {
    action: Nullable<string>;
    created_at: string;
    email: Nullable<string>;
    ip_address: Nullable<string>;
    level: string;
    message: string;
    properties: string;
    user_agent: Nullable<string>;
    correlation_id: Nullable<string>;
    component: Nullable<string>;
};

const QUERY_NAME = 'audit_log';

export const useAuditLog = (params?: Filter[], prefix = '') => {
    const {
        onPageTokenMappingChange,
        page,
        onPage,
        onLimitChange,
        limit,
        query,
        pageTokeMapping,
        search,
        onSearchChange,
        order,
        onOrderChange,
        orderDirection
    } = useTableFilters({
        variant: 'query',
        searchKey: 'message',
        initialOrdering: 'created_at',
        params,
        prefix
    });

    const requestQuery = {
        name: QUERY_NAME,
        pageSize: query.page_size,
        pageToken: query.page_token,
        params: query.params,
        orderByParams: query.orderByParams
    };
    const { data, error, mutate, isLoading, isValidating } = useSWR<QueryReturnResult<AuditLogItem>>(
        ['/audit-log', requestQuery],
        async ([_, query]) => runQuery(query as any)
    );

    useEffect(() => {
        const shouldUpdate = data && !isLoading && data.next_page_token;
        if (shouldUpdate) {
            onPageTokenMappingChange((current) => ({
                ...current,
                [page + 1]: data.next_page_token
            }));
        }
    }, [data, isLoading, onPageTokenMappingChange, page]);

    return {
        data,
        error,
        mutate,
        isLoading,
        onPageTokenMappingChange,
        page,
        onPage,
        orderDirection,
        onLimitChange,
        limit,
        query,
        pageTokeMapping,
        search,
        onSearchChange,
        isValidating,
        order,
        onOrderChange
    };
};
