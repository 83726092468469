import { Filter } from "types";
import { useInsightQuery } from "./use-insight-query";


const AVERAGE_PRODUCT_IMPACT_QUERY_NAME = 'average_product_impact';

export const useAverageProductImpactReport = (params?: Filter[])  => {
  

    const averageProductImpactReportResult = useInsightQuery<{
        absolute_alop: number;        
        absolute_water_depletion:number;     
        gwp_absolute: number;
    }>(AVERAGE_PRODUCT_IMPACT_QUERY_NAME);

    
    return {averageProductImpactReportResult, params }
};
