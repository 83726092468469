import { useAuth } from 'account-management/auth/use-auth';
import { paths } from 'paths';
import { useUser } from 'shared/use-user';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
    TbBrandGoogleBigQuery,
    TbDashboard,
    TbDatabaseImport,
    TbHome,
    TbList,
    TbReportAnalytics,
    TbUserCheck,
    TbUsersGroup
} from 'react-icons/tb';
import { Link, useLocation } from 'react-router-dom';
import { NavLink as MantineNavLink, Stack, Skeleton, Group, Tooltip } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { FlagName } from 'utils/get-flag-value';
import { LogoIndicator } from './logo-indicator';
import { PreviewBadge } from './preview-badge';
import { useFeatureEnabled } from './use-feature-enabled';
import { useOrganizationId } from './use-organization-id';

interface NavLinkProps {
    icon: ReactNode;
    to?: string;
    label: string;
    tooltip?: string;
    preview?: boolean;
    disabled: boolean;
    hidden: boolean;
    onClick: () => void;
    subLinks?: NavLinkProps[];
    rightSection?: ReactNode;
}

const NavLink = ({
    icon,
    label,
    to,
    disabled = false,
    onClick,
    subLinks,
    hidden = false,
    rightSection,
    tooltip,
    preview = false
}: NavLinkProps) => {
    const location = useLocation();
    const isActive = location.pathname === to;

    if (hidden) return null;

    if (subLinks) {
        return (
            <MantineNavLink
                label={
                    <Group gap="xs">
                        {label}
                        {rightSection}
                    </Group>
                }
                leftSection={icon}
                childrenOffset={16}
            >
                {subLinks.map((subLink) => (
                    <NavLink key={subLink.label} {...subLink} onClick={onClick} />
                ))}
            </MantineNavLink>
        );
    }

    if (to) {
        return (
            <Tooltip label={tooltip} disabled={!tooltip}>
                <MantineNavLink
                    active={isActive}
                    label={label}
                    component={Link}
                    disabled={disabled}
                    rightSection={rightSection ?? (preview ? <PreviewBadge /> : undefined)}
                    leftSection={icon}
                    onClick={() => onClick()}
                    aria-current={isActive ? 'page' : undefined}
                    to={to}
                    noWrap
                />
            </Tooltip>
        );
    }

    return null;
};

export const MainLinks = ({ onClick }: { onClick: () => void }) => {
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');
    const { t } = useTranslation();
    const { isMultifactorRequired } = useAuth();
    const { hasPermission, isDayrize, isLoading, activeUser, error } = useUser();
    const { isEnabled } = useFeatureEnabled();

    const organizationId = useOrganizationId();
    const key = 'product';
    const isInitialized = activeUser && !isLoading && !error;

    const hasRuleConfigAccess = hasPermission('/rule_manager/rule_configs/query');
    const hasColumnMappingAccess = hasPermission('/ingest/file/column-mapping');

    const hasDataManagementAccess = hasRuleConfigAccess || hasColumnMappingAccess || hasPermission('/ingest/files/query');
    const hasAccountManagementAcess =
        hasPermission('/query_manager/query/execute', 'POST') ||
        hasPermission('/account_manager/users/query') ||
        hasPermission('/account_manager/groups/query') ||
        hasPermission('/account_manager/organizations/query');

    const links = isMultifactorRequired
        ? []
        : ([
              {
                  icon: <TbHome size={20} color="inherit" />,
                  to: paths.home(organizationId),
                  label: t('home')
              },
              hasPermission('/query_manager/query/execute', 'POST') && {
                  icon: <TbDashboard size={20} color="inherit" />,
                  to: paths.dashboard(organizationId),
                  label: t('dashboard')
              },
              hasPermission('/query_manager/query/execute', 'POST') && {
                  icon: <TbList size={20} color="inherit" />,
                  to: paths.products(organizationId),
                  label: t('products')
              },
              hasPermission('/query_manager/query/execute', 'POST') && {
                  icon: <TbReportAnalytics size={20} color="inherit" />,
                  label: t('insights'),
                  subLinks: [
                      {
                          to: paths.insights.materialInsights(organizationId),
                          label: t('materialInsights')
                      },
                      isEnabled(FlagName.EnterpriseScoreComparison) &&
                          hasPermission('/query_manager/query/execute', 'POST') && {
                              to: paths.insights.enterpriseScoreComparison(organizationId, key),
                              label: t('scoreComparison'),
                              preview: isEnabled(FlagName.EnterpriseScoreComparison, 'preview')
                          },
                      isEnabled(FlagName.LookerDashboards) &&
                          hasPermission('/query_manager/query/execute', 'POST') && {
                              to: paths.insights.productComparisonDashboard(organizationId),
                              label: t('productComparisonDashboard')
                          },
                      isEnabled(FlagName.LookerDashboards) &&
                          hasPermission('/query_manager/query/execute', 'POST') && {
                              to: paths.insights.scorecards(organizationId),
                              label: t('scorecardDashboard')
                          },
                      isEnabled(FlagName.Prism) &&
                          hasPermission('/query_manager/query/execute', 'POST') && {
                              to: paths.insights.prism(organizationId),
                              label: t('prism')
                          },
                      isEnabled(FlagName.ActionableInsights) &&
                          hasPermission('/query_manager/query/execute', 'POST') && {
                              to: paths.insights.actionableInsightsComparison(organizationId),
                              label: t('actionableInsights'),
                              preview: isEnabled(FlagName.ActionableInsights, 'preview')
                          },
                      isEnabled(FlagName.DataQualityRating) &&
                          hasPermission('/query_manager/query/execute', 'POST') && {
                              to: paths.insights.dataQualityRating(organizationId),
                              label: t('dataQualityRating')
                          },
                      isEnabled(FlagName.LookerDashboards) &&
                          hasPermission('/query_manager/query/execute', 'POST') && {
                              to: paths.insights.dataQualityInsights(organizationId),
                              label: t('dataQualityInsights')
                          }
                  ]
              },
              hasPermission('/query_manager/queries/query') && {
                  icon: <TbBrandGoogleBigQuery size={20} color="inherit" />,
                  to: paths.queries(organizationId),
                  label: t('queries')
              },
              hasDataManagementAccess && {
                  icon: <TbDatabaseImport size={20} color="inherit" />,
                  label: t('dataManagement'),
                  subLinks: [
                      hasPermission('/ingest/files/query') && {
                          to: paths.dataManagement.ingest(organizationId),
                          label: t('dataIngestion')
                      },
                      hasPermission('/query_manager/query/execute', 'POST') && {
                          to: paths.dataManagement.downloadScoreData(organizationId),
                          label: t('downloadScoreData')
                      },
                      hasPermission('/query_manager/query/execute', 'POST') &&
                          isEnabled(FlagName.HoldingArea) && {
                              to: paths.dataManagement.holdingArea(organizationId),
                              label: t('holdingArea')
                          },
                      isEnabled(FlagName.BulkImportEdit) &&
                          hasPermission('/query_manager/query/execute', 'POST') &&
                          !isSmallDevice && {
                              to: paths.dataManagement.bulkImportEdit(organizationId),
                              label: t('bulkImportAndEdit'),
                              preview: isEnabled(FlagName.BulkImportEdit, 'preview')
                          },
                      hasColumnMappingAccess && {
                          to: paths.dataManagement.columnMapping(organizationId),
                          label: t('columnMapping'),
                          hidden: true
                      },
                      hasRuleConfigAccess && {
                          to: paths.dataManagement.ruleConfig(organizationId),
                          label: t('ruleConfig')
                      }
                  ]
              },
              hasAccountManagementAcess && {
                  icon: <TbUsersGroup size={20} color="inherit" />,
                  label: t('accountManagement'),
                  subLinks: [
                      hasPermission('/query_manager/query/execute', 'POST') && {
                          to: paths.accountManagement.auditLog(organizationId),
                          label: t('auditLog')
                      },
                      hasPermission('/account_manager/groups/query') && {
                          to: paths.accountManagement.groups(organizationId),
                          label: t('groups')
                      },
                      hasPermission('/account_manager/users/query') && {
                          to: paths.accountManagement.users(organizationId),
                          label: t('users')
                      },
                      isEnabled(FlagName.PartnerOrganizations) &&
                          hasPermission('/account_manager/organizations/query') && {
                              to: paths.accountManagement.organizations(organizationId),
                              label: 'Organizations',
                              preview: isEnabled(FlagName.PartnerOrganizations, 'preview')
                          }
                  ]
              },
              isDayrize && {
                  icon: <TbUserCheck size={20} color="inherit" />,
                  rightSection: <LogoIndicator />,
                  label: t('customerSuccess'),
                  subLinks: [
                      hasPermission('/ingest/schemas/product') && {
                          to: paths.customerSuccess.singleProductUpload(organizationId),
                          label: t('singleProductUpload')
                      },
                      hasPermission('/') && {
                          to: paths.customerSuccess.namespaces(organizationId),
                          label: t('namespaces')
                      },
                      hasPermission('/') && {
                          to: paths.customerSuccess.createOrganization(organizationId),
                          label: t('createOrganization')
                      },
                      hasPermission('/') && {
                          to: paths.customerSuccess.materials(organizationId),
                          label: t('materialsList')
                      },
                      {
                          to: paths.customerSuccess.utilities(organizationId),
                          label: t('utilities')
                      }
                  ]
              }
          ].filter((current) => !!current) as NavLinkProps[]);

    if (!isInitialized) {
        return (
            <Stack p="sm" gap="sm" data-testid="loading">
                <Skeleton height={22} width="100%" radius="xs" />
                <Skeleton height={22} width="100%" radius="xs" />
                <Skeleton height={22} width="100%" radius="xs" />
                <Skeleton height={22} width="100%" radius="xs" />
            </Stack>
        );
    }

    return (
        <>
            {links.map((link) => (
                <NavLink {...link} onClick={onClick} key={link.label} />
            ))}
        </>
    );
};
