import { Dashboard } from 'types';

export const schema: Dashboard = {
    name: 'Welcome to Dayrize',
    widgets: [
        {
            type: 'value',
            name: 'Products',
            span: 3,
            query_name: 'product_scoring_results',
            link: '/organization/{organizationId}/score-comparison/product',
            variant: 'count',
            pageSize: 1
        },
        {
            type: 'value',
            name: 'Brands',
            span: 3,
            query_name: 'product_brands',
            link: '/organization/{organizationId}/score-comparison/brand',
            variant: 'count',
            pageSize: 1
        },
        {
            type: 'value',
            name: 'Categories',
            span: 3,
            query_name: 'suppliers',
            link: '/organization/{organizationId}/score-comparison/category',
            variant: 'count',
            pageSize: 1
        },
        {
            type: 'value',
            name: 'Suppliers',
            span: 3,
            query_name: 'product_categories',
            link: '/organization/{organizationId}/score-comparison/division',
            variant: 'count',
            pageSize: 1
        },
        {
            type: 'list',
            variant: 'score',
            name: 'Top 5 highest scored',
            description: 'The products with the highest total score for the selected period.',
            pageSize: 5,
            span: 6,
            query_name: 'product_scoring_results',
            selects: [
                {
                    type: 'interval',
                    column_name: 'scored_at'
                }
            ],
            ordering: [
                {
                    name: 'total_score',
                    desc: true
                }
            ],
            label_column_name: 'name',
            value_column_name: 'total_score',
            link: `/organization/{organizationId}/products/{id}/score-report/total?name={name}&sku={sku}`
        },
        {
            type: 'list',
            variant: 'score',
            name: 'Top 5 lowest scored',
            description: 'The products with the lowest total score for the selected period.',
            pageSize: 5,
            span: 6,
            query_name: 'product_scoring_results',
            selects: [
                {
                    type: 'interval',
                    column_name: 'scored_at'
                }
            ],
            ordering: [
                {
                    name: 'total_score',
                    desc: false
                }
            ],
            label_column_name: 'name',
            value_column_name: 'total_score',
            link: `/organization/{organizationId}/products/{id}/score-report/total?name={name}&sku={sku}`
        },
        {
            type: 'product-list',
            span: 12,
            variant: 'score',
            name: 'Average Product Impact',
            query_name: 'product_scoring_results',
            label_column_name: 'name',
            value_column_name: 'total_score',
            link: '/organization/{organizationId}/score-comparison/carbon_emissions'
        },
        {
            type: 'table',
            variant: 'score',
            name: 'Most Impactful materials',
            description: 'The products with the lowest total score for the selected period.',
            pageSize: 5,
            span: 12,
            query_name: 'product_scoring_results',
            selects: [
                {
                    type: 'interval',
                    column_name: 'scored_at'
                }
            ],
            ordering: [
                {
                    name: 'total_score',
                    desc: false
                }
            ],
            label_column_name: 'name',
            value_column_name: 'total_score',
            link: `/organization/{organizationId}/products/{id}/score-report/total?name={name}&sku={sku}`
        },
        {
            type: 'most-impactful-materials',
            name: 'Most Impactful Materials',
            span: 12,
            query_name: '',
            link: '/organization/{organizationId}/material-insights',
            variant: 'count',
            pageSize: 5
        },
        {
            type: 'data-quality',
            name: 'Data Quality',
            span: 12,
            query_name: 'product_scoring_results',
            link: '/organization/{organizationId}/data-quality-rating',
            variant: 'count',
            pageSize: 5
        },
        {
            type: 'insight-circularity-widget',
            name: 'Circularity of Materials',
            span: 12,
            query_name: 'insight_circularity_of_materials',
            link: '/organization/{organizationId}/material-insights?tab=Circularity+of+Materials',
            variant: 'count',
            pageSize: 1
        },
        {
            type: 'insight-widget',
            render: false,
            name: 'Actionable Insights',
            span: 12,
            query_name: 'actionable_insights',
            link: '/organization/{organizationId}/actionable-insights',
            variant: 'count',
            pageSize: 1
        }
    ]
};
