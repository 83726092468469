import { Section } from 'shared/section';
import { useOrganizationId } from 'shared/use-organization-id';
import { ProductList } from 'types';
import { Link, useNavigate } from 'react-router-dom';
import { Anchor, Box, Group, Text, rem, useMantineColorScheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import landSrc from '../../src/shared/land.svg';
import carbonSrc from '../../src/shared/carbon.svg';
import waterSrc from '../../src/shared/water.svg';
export const ProductListCard = ({ title, data }: { title: string; data: ProductList[] }) => {
    const organizationId = useOrganizationId() || '';
    const { colorScheme } = useMantineColorScheme();
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');
    const iconSrcMap = {
        heavy_truck: landSrc,
        medium_truck: landSrc,
        light_truck: landSrc,
        inland_waterway_freight: waterSrc,
        oceanic_transport: waterSrc,
        airplane: carbonSrc,
        freight_train: carbonSrc,
        diesel_freight_train: carbonSrc,
        electric_freight_train: carbonSrc,
     
     
    };
    const navigate = useNavigate();

    const handleClick = (to: string | undefined) => {
        if (to) {
            navigate(to.replaceAll('{organizationId}', organizationId));
        }
    };

    return (
        <Section style={{ flex: '1 !important', minWidth: rem(300) }}>
            <Group mb="lg" justify="space-between" align="center" gap={0}>
                <Group gap={rem(2)}>
                    <Text fw={700} size="md">
                        {title}
                    </Text>
                </Group>
            </Group>

            <Box
                component="ul"
                style={{
                    padding: 0,
                    display: 'flex',
                    flexDirection: !isSmallDevice ? 'row' : 'column',
                    marginBottom: 0
                }}
            >
                {data?.map((current: ProductList) => (
                    <Box
                        key={current.label}
                        component="li"
                        style={{
                            display: 'flex',
                            cursor: 'pointer',
                            margin: '5px',
                            width: !isSmallDevice ? '33%' : '100%'
                        }}
                    >
                        <Group
                            align="center"
                            gap="xs"
                            style={{
                                background: current.color,
                                height: '60px',
                                width: '100%',
                                cursor: 'pointer',
                                borderRadius: 'var(--paper-radius)',
                                boxShadow: 'var(--paper-shadow)',
                                backgroundColor: 'var(--mantine-color-body);'
                            }}
                        >
                            <button
                                onClick={() => handleClick(current.to)}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    width: '100%',
                                    textAlign: 'left'
                                }}
                                aria-label="Data Quality Rating"
                                tabIndex={0}
                            >
                                {current.to ? (
                                    <>
                                        <Box
                                            component="img"
                                            style={{
                                                filter: colorScheme === 'dark' ? 'brightness(10)' : 'none',
                                                verticalAlign: 'middle',
                                                position: 'relative',
                                                top: '9px'
                                            }}
                                            src={iconSrcMap[current.icon]}
                                            alt=""
                                            width={32}
                                            height={32}
                                        />
                                        <Anchor
                                            style={{
                                                color: 'white',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                position: 'relative',
                                                bottom: '57px',
                                                textDecoration: 'none'
                                            }}
                                            lineClamp={1}
                                            ta="left"
                                            underline="hover"
                                            component={Link}
                                            to={current.to.replaceAll('{organizationId}', organizationId)}
                                        >
                                            <div
                                                style={{
                                                    color: '#FFFF',
                                                    fontWeight: 'bolder',
                                                    fontSize: '25px',
                                                    width: '30px',
                                                    marginLeft: '40px',
                                                    position: 'relative',
                                                    top: '30px'
                                                }}
                                            >
                                                {current.value}
                                            </div>
                                            <div
                                                style={{
                                                    margin: '20px',
                                                    position: 'relative',
                                                    left: '20px',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {current.label}
                                            </div>
                                        </Anchor>
                                    </>
                                ) : (
                                    <>
                                        <Box
                                            component="img"
                                            style={{ filter: colorScheme === 'dark' ? 'brightness(10)' : 'none' }}
                                            src={iconSrcMap[current.icon]}
                                            alt=""
                                            width={32}
                                            height={32}
                                            margin-left={20}
                                        />
                                        <Text lineClamp={1} style={{ color: 'white' }}>
                                            {current.value}
                                        </Text>
                                        <Text lineClamp={1} style={{ color: 'white' }}>
                                            {current.label}
                                        </Text>
                                    </>
                                )}
                            </button>
                        </Group>
                    </Box>
                ))}
            </Box>
        </Section>
    );
};
