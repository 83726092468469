import { DownloadButton } from 'shared/download-button';
import { ErrorCard } from 'shared/error-card';
import { ProductFilters } from 'shared/product-filters';
import { TooManyResultsAlert } from 'shared/too-many-results-alert';
import { useOrganizationId } from 'shared/use-organization-id';
import { useProductFilters } from 'shared/use-product-filters';
import { Dimension } from 'types/shared';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Group, Select, Tabs, Text, Title, Divider } from '@mantine/core';
import { DEFAULT_MAX_RESULTS } from 'utils/run-query';
import { ComparisonChart } from './comparison-chart';
import { getComparisonChartNavigationPath } from './get-comparison-chart-navigation-path';
import classes from './tabs.module.css';
import { useEnterpriseScoreComparison, ScoreComparisonVariant } from './use-enterprise-score-comparison';
import { useOrdering } from './use-ordering';

const variantOptions: Array<{ label: string; value: ScoreComparisonVariant }> = [
    { label: 'Product', value: 'product' },
    { label: 'Category', value: 'category' },
    { label: 'Brand', value: 'brand' },
    { label: 'Division', value: 'division' }
];

const metricOptions: Array<{
    label: string;
    value: Dimension;
}> = [
    { label: 'Dayrize Score', value: Dimension.Total },
    { label: 'Circularity', value: Dimension.Circularity },
    { label: 'Ecosystem Impact', value: Dimension.Ecosystem },
    { label: 'Climate Impact', value: Dimension.Climate },
    { label: 'Livelihood & Wellbeing', value: Dimension.Livelihood },
    { label: 'Purpose', value: Dimension.Purpose },
    { label: 'Carbon Emissions', value: Dimension.CarbonEmissions },
    { label: 'Water Depletion', value: Dimension.WaterDepletion },
    { label: 'Land Use', value: Dimension.LandUse }
];

export const EnterpriseScoreComparison = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const organizationId = useOrganizationId();
    const { ordering, onOrderingChange, orderingOptions, isDescending } = useOrdering();
    const { t } = useTranslation();
    const [dimension, setDimension] = useState(metricOptions[0].value);
    const [variant, setVariant] = useState<ScoreComparisonVariant>(variantOptions[0].value);
    const { filters, ...rest } = useProductFilters();

    const { formatted, error, isLoading, data } = useEnterpriseScoreComparison(variant, dimension, filters, isDescending);

    const tabs = variantOptions.map((tab) => ({ label: tab.label, value: tab.value }));

    // Set variant and dimension based on id from the URL
    useEffect(() => {
        const matchedVariant = variantOptions.find((option) => option.value === id)?.value;
        const matchedMetric = metricOptions.find((option) => option.value === id)?.value;
        if (matchedVariant) {
            setVariant(matchedVariant);
        }
        if (matchedMetric) {
            setDimension(matchedMetric);
        }
    }, [id]);

    return (
        <>
            <Group justify="space-between" align="center" mb="md" style={{ margin: '10px' }}>
                <Box>
                    <Title size="h3">{t('scoreComparison')}</Title>
                    <Text c="dimmed" size="sm">
                        {t('enterpriseScoreComparisonDescription')}
                    </Text>
                </Box>
                <ProductFilters
                    {...rest}
                    enabledFilters={['parentCategory', 'category', 'brand', 'tags']}
                    additionalFilters={
                        <Select
                            allowDeselect={false}
                            label={t('ordering')}
                            onChange={onOrderingChange}
                            value={ordering}
                            data={orderingOptions}
                        />
                    }
                >
                    <DownloadButton
                        exports={[
                            {
                                name: `${dimension}_brand_score_comparison`,
                                data: data?.results
                            }
                        ]}
                        disabled={!data?.results}
                    />
                </ProductFilters>
            </Group>
            {data?.num_results === DEFAULT_MAX_RESULTS && <TooManyResultsAlert maxResults={DEFAULT_MAX_RESULTS} />}
            <Box style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <Tabs
                    variant="outline"
                    value={variant}
                    onChange={(value) => {
                        if (value) setVariant(value as ScoreComparisonVariant);
                    }}
                    style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
                    classNames={{ tab: classes.tab, panel: classes.panel }}
                >
                    <Tabs.List>
                        {tabs.map((tab) => (
                            <Tabs.Tab key={tab.label} value={tab.value}>
                                {tab.label}
                            </Tabs.Tab>
                        ))}
                    </Tabs.List>

                    <Tabs.Panel value={variant} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <Group align="center" spacing="xs" gap="sm">
                            <Text>Metric:</Text>
                            <Select
                                value={dimension}
                                onChange={(value) => {
                                    if (value) setDimension(value as Dimension);
                                }}
                                data={metricOptions}
                                allowDeselect={false}
                            />
                        </Group>
                        <Divider my="md" />
                        {error ? (
                            <ErrorCard error={error} />
                        ) : (
                            <ComparisonChart
                                onClick={(data) => {
                                    navigate(getComparisonChartNavigationPath(variant, organizationId, data));
                                }}
                                data={formatted}
                                isLoading={isLoading}
                                dimension={dimension}
                            />
                        )}
                    </Tabs.Panel>
                </Tabs>
            </Box>
        </>
    );
};
